const backFirstPart = "XXXXXX";
let backFullUrl = "";
let baseUrl;
if (backFullUrl) {
    if (backFullUrl.endsWith("/")) {
        backFullUrl = backFullUrl.slice(0, -1);
    }
    baseUrl = backFullUrl;
} else {
    const schema = window.location.protocol;
    const hostname = window.location.hostname;
    const [, ...lastParts] = hostname.split(".");
    baseUrl = `${schema}//${backFirstPart}.${lastParts.join(".")}`;
}
module.exports = { baseURI: baseUrl };
