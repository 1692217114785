import React from "react";
import { Line } from "react-chartjs-2";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import staticData from "./data";
import config from "../config";
import { Trans } from "react-i18next";
import i18next from "i18next";
import cookie from "react-cookies";

export class Detail extends React.Component {
    render() {
        return (
            <div className="content">
                <Title content="Détail du calcul des index"/>
                <DetailCard/>
            </div>
        );
    }
}

export class DetailCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToPass: {},
            shouldRedraw: false,
            isLoading: true,
            currentBase: "",
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: "left",
                        labels: {
                            padding: 20,
                            boxWidth: 20,
                        },
                        onClick: this.onLegendClick,
                    },
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: "y",
                        },
                        limits: {
                            y: {
                                min: 70,
                                max: 130,
                            },
                        },
                        zoom: {
                            wheel: {
                                enabled: true,
                            },
                        },
                    },
                    tooltip: {
                        enabled: true,
                    },
                },
            },
        };
    }

    async componentDidMount() {
        await new Promise(resolve => this.setState({ currentBase: "2012" }, resolve));
        await this.changeBase();
    }

    async onLegendClick(e, legendItem, legend) {
        const ci = legend.chart;
        const Chart = ci.constructor;
        const nbLines = ci.data.datasets.length;
        const clickedIndex = legendItem.datasetIndex;
        const selected = legendItem.hidden; // yes because the hidden option is not updated yet.
        if (clickedIndex == 0) { // Select/Unselect all option
            for (let index = 1; index < nbLines; index++) {
                ci.getDatasetMeta(index).hidden = !selected;
            }
        } else {
            const nbLinesSelected = [...Array(nbLines).keys()].filter(index => {
                if (index == 0) {
                    return true;
                } else if (index == clickedIndex) {
                    return selected;
                } else {
                    return !ci.getDatasetMeta(index).hidden;
                }
            }).length;
            ci.getDatasetMeta(0).hidden = nbLinesSelected < nbLines;
        }
        Chart.defaults.plugins.legend.onClick.bind(this)(e, legendItem, legend);
    }

    async changeBase(e) {
        if (e) {
            await new Promise(resolve => this.setState({ currentBase: e.target.value }, resolve));
        }
        const indexes = { labels: [], datasets: [] };
        const indexesList = [];
        const idsToDisplay = [1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const response = await axios.get(`${config.baseURI}/api/revision/${responseRevision.data[0].id}/indiceparameters/`);
        for (let j = 0; j < response.data.length; j++) {
            if (idsToDisplay.indexOf(response.data[j].id) > -1) {
                indexesList.push({ name: i18next.t(response.data[j].indice.name) + " " + response.data[j].country.name, id: response.data[j].id });
            }
        }
        const promises = [];
        indexesList.forEach((e, i) => {
            const url = `${config.baseURI}/api/revision/${responseRevision.data[0].id}/indiceparameter/${(i + 1)}/values/?base100_date=${this.state.currentBase}-01`;
            promises.push(axios.get(url));
        });
        const results = await axios.all(promises);
        results.forEach((response, index) => {
            const values = [];
            // Fill array with nulls if it lacks data after 2006
            for (let i = response.data.length - 1; i >= 0; i--) {
                if (moment(response.data[i].date, "YYYY-MM-DD").isAfter(moment("2005-12-31", "YYYY-MM-DD"))) {
                    values.unshift(response.data[i].value);
                }
                if (index == 0) {
                    indexes.labels.unshift(response.data[i].date);
                }
            }
            const diff = moment(response.data[0].date, "YYYY-MM-DD").diff(moment("2006-01-01", "YYYY-MM-DD"), "months");
            if (diff > 0) {
                for (let j = diff; j >= 0; j--) {
                    values.unshift(null);
                }
            }
            indexes.datasets.push({
                label: indexesList[index].name,
                fill: false,
                pointHoverRadius: 5,
                pointRadius: 1,
                pointHitRadius: 10,
                data: values,
                spanGaps: false,
                borderColor: staticData.colorList[index],
                lineTension: 0,
            });
        });
        indexes.datasets.sort((a, b) => { return a.label.localeCompare(b.label); });
        indexes.datasets.unshift({
            label: i18next.t("Tout sélectionner"),
            fill: false,
            data: [],
            spanGaps: false,
            lineTension: 0,
        });
        await new Promise(resolve => this.setState({ shouldRedraw: true, isLoading: false, dataToPass: indexes }, resolve));
    }

    render() {
        if (cookie.load("token")) {
            if (!this.state.isLoading) {
                return (
                    <div className="detailCard">
                        <Line
                            type="line"
                            data={this.state.dataToPass}
                            options={this.state.options}
                            height={110}
                        />
                        <div className="chartBase">
                            <div className="chartBaseText"><Trans>Calculs réalisés en base 100</Trans></div>
                            <select defaultValue="2012" onChange={(e) => this.changeBase(e)}>
                                <option value="2010" key="2010">2010</option>
                                <option value="2012" key="2012">2012</option>
                            </select>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="detailCard">
                        <div className="loaderCentered"></div>
                    </div>
                );
            }
        } else {
            return (
                <div className="contentAdmin">
                    <div className="actionAdmin">
                        <Trans>Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation</Trans>
                    </div>
                </div>
            );
        }
    }
}
