import React from "react";
import { createRoot } from "react-dom/client";
import { Router, Route, IndexRoute, hashHistory } from "react-router";
import { I18nextProvider } from "react-i18next";

import {
    Chart,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { NltlGraph, OthersGraph } from "./components/graph.js";
import { Table } from "./components/table.js";
import { Detail } from "./components/detail.js";
import { WBS } from "./components/wbs.js";
import { Login } from "./components/login.js";
import { Admin } from "./components/admin.js";
import { Indexes } from "./components/indexes.js";
import { Costs } from "./components/costs.js";
import { WBSManagement } from "./components/wbsmanagement.js";
import { RevisionManagement } from "./components/revisionmanagement.js";
import { IndexesManagement } from "./components/indexesmanagement.js";
import Layout from "./components/layout.js";

import i18n from "./i18n";
import "bootstrap/dist/css/bootstrap.min.css";

import "../scss/main.scss";
import "../scss/hover.css";
import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";

Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Legend);
Chart.register(Tooltip);
Chart.register(zoomPlugin);

const app = document.getElementById("app");
const root = createRoot(app);
root.render(
    <I18nextProvider i18n={ i18n }>
        <Router history={hashHistory}>
            <Route path="/" component={Layout}>
                <IndexRoute component={Table}></IndexRoute>
                <Route path="nltlgraph" component={NltlGraph}></Route>
                <Route path="othersgraph" component={OthersGraph}></Route>
                <Route path="detail" component={Detail}></Route>
                <Route path="wbs" component={WBS}></Route>
                <Route path="login" component={Login}></Route>
                <Route path="admin" component={Admin}></Route>
                <Route path="indexes" component={Indexes}></Route>
                <Route path="costs" component={Costs}></Route>
                <Route path="wbsmanagement" component={WBSManagement}></Route>
                <Route path="revisionmanagement" component={RevisionManagement}></Route>
                <Route path="indexesmanagement" component={IndexesManagement}></Route>
            </Route>
        </Router>
    </I18nextProvider>,
);
