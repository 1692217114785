import React from "react";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import config from "../config";
import { Trans } from "react-i18next";

export class WBS extends React.Component {
    render() {
        return (
            <div className="content">
                <Title content="Work Breakdown Structure"/>
                <WBSCard/>
            </div>
        );
    }
}

export class WBSCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: {
                header: [],
                data: null,
            },
            isLoading: true,
        };
    }

    async componentDidMount() {
        const table = {
            header: [],
            data: {},
        };
        const paramResponse = await axios.get(config.baseURI + "/api/wbs/parameters/");
        table.data["Génie Civil + environnement + déviations réseaux"] = { IT: [], FR: [] };
        paramResponse.data.forEach(function (e) {
            table.data[e.name] = { IT: [], FR: [] };
        });
        table.data["Total"] = { IT: [], FR: [], Total: [] };
        const wbsResponse = await axios.get(config.baseURI + "/api/wbs/");
        const promises = [];
        wbsResponse.data.forEach(function (e) {
            table.header.unshift({ name: e.label, subtitle: moment(e.date_published).format("MM/YYYY") });
            const url = config.baseURI + "/api/wbs/" + e.id + "/parameters/";
            promises.push(axios.get(url));
        });
        table.header.unshift({ name: "Pays", subtitle: "" });
        table.header.unshift({ name: "Version du projet", subtitle: "Md€ aux conditions économiques de", class: "wbsHeaderLeftCell" });
        const results = await axios.all(promises);
        results.forEach(result => {
            result.data.forEach(function (line) {
                table.data[line.wbs_parameter.name][line.wbs_parameter.country.name].unshift(line.value_mde);
            });
        });
        const promisesTotal = [];
        wbsResponse.data.forEach(function (e) {
            const url = config.baseURI + "/api/wbs/" + e.id + "/total/";
            promisesTotal.push(axios.get(url));
        });
        const resultsTotal = await axios.all(promisesTotal);
        resultsTotal.forEach(responseTotal => {
            Object.keys(responseTotal.data).map(function (key) {
                table.data[key]["IT"].unshift(responseTotal.data[key].IT);
                table.data[key]["FR"].unshift(responseTotal.data[key].FR);
                if (key == "Total") {
                    table.data[key]["Total"].unshift(responseTotal.data[key].Total);
                }
            });
        });
        await new Promise(resolve => this.setState({ table: table, isLoading: false }, resolve));
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <div className="WBSCard">
                    <table className="table-striped">
                        <thead>
                            <tr>
                                {this.state.table.header.map(function (item, index) {
                                    return (
                                        <th key={item.name} className={item.class}>
                                            {index == 0 &&
                                                [
                                                    <div key={item.name}><Trans>{item.name}</Trans></div>,
                                                    <div key={item.name + "s"} className="tableHeaderSubtitle"><Trans>{item.subtitle}</Trans></div>,
                                                ]
                                            }
                                            {index > 0 &&
                                                [
                                                    <div key={item.name} className="tableHeader"><Trans>{item.name}</Trans></div>,
                                                    <div key={item.name + "s"} className="tableHeaderSubtitle">{item.subtitle}</div>,
                                                ]
                                            }
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.table.data).map(function (key, index) {
                                return [
                                    <tr key={"a" + key}>
                                        <td rowSpan={Object.keys(this.state.table.data[key]).length} key={key} className={"row" + index % 2}><Trans>{key}</Trans></td>
                                        <td className="countryCell">FR</td>
                                        {this.state.table.data[key].FR.map(function (item, index) {
                                            if (index == 0) {
                                                return <td key={item}>{item.toFixed(3)}</td>;
                                            } else {
                                                return <td className={"oldWBS"} key={item}>{item.toFixed(3)}</td>;
                                            }
                                        })}
                                    </tr>,
                                    <tr key={"b" + key}>
                                        <td className="countryCell">IT</td>
                                        {this.state.table.data[key].IT.map(function (item, index) {
                                            if (index == 0) {
                                                return <td key={item}>{item.toFixed(3)}</td>;
                                            } else {
                                                return <td className={"oldWBS"} key={item}>{item.toFixed(3)}</td>;
                                            }
                                        })}
                                    </tr>,
                                ];
                            }.bind(this))}
                            <tr>
                                <td className="totalCell"></td>
                                {this.state.table.data["Total"].Total.map(function (item) {
                                    return <td key={item} className="totalCell">{item.toFixed(3)}</td>;
                                })}
                            </tr>
                        </tbody>
                    </table>
                </div>
            );
        }
        return (
            <div className="WBSCard">
                <div className="loaderCentered"></div>
            </div>
        );
    }
}
