import React from "react";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import config from "../config";
import { Trans } from "react-i18next";
import cookie from "react-cookies";

export class RevisionManagement extends React.Component {
    render() {
        return (
            <div className="content">
                <Title content="Gérer les révisions"/>
                <RevisionManagementCard/>
            </div>
        );
    }
}

export class RevisionManagementCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: ["Date de création", "Etat", "Active", "Actions"],
            table: null,
            isLoading: true,
            isUploading: false,
            message: " ",
            icon: "",
            progress: 0,
            confirmDelete: false,
            itemToDelete: null,
        };
        this.newRevision = this.newRevision.bind(this);
    }

    async getRevisionList() {
        await new Promise(resolve => this.setState({ isLoading: true }, resolve));
        const response = await axios.get(config.baseURI + "/api/revision/");
        const table = response.data;
        table.sort((a, b) => { return a.id - b.id; });
        await new Promise(resolve => this.setState({ table: table, isLoading: false }, resolve));
    }

    async componentDidMount() {
        await this.getRevisionList();
    }

    closePopUp() {
        this.setState({ confirmDelete: false });
    }

    confirmDelete(id, date) {
        this.setState({ confirmDelete: true, itemToDelete: { date: date, id: id } });
    }

    async getStatus(id) {
        const response = await axios.get(config.baseURI + "/api/revision/" + id);
        if (response.data.state.label === "Available") {
            await this.getRevisionList();
            await new Promise(resolve => this.setState({ isUploading: false, message: "Nouvelle révision créée avec succès et publiée", icon: "check" }, resolve));
        } else if (response.data.state.label === "Error") {
            await this.getRevisionList();
            await new Promise(resolve => this.setState({ isUploading: false, message: "Problème lors la création de la révision. Réessayez plus tard", icon: "times" }, resolve));
        } else {
            await new Promise(resolve => this.setState({ progress: response.data.percent_completed }, resolve));
            setTimeout(async () => { await this.getStatus(response.data.id); }, 3000);
        }
    }

    async newRevision() {
        await new Promise(resolve => this.setState({ isUploading: true }, resolve));
        const response = await axios.post(
            config.baseURI + "/api/revision/",
            {
                state: {},
                is_published: true,
                date_revision: moment().format(),
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + cookie.load("token"),
                },
            },
        ).catch(async error => {
            await new Promise(resolve => this.setState({ message: error.response }, resolve));
            return null;
        });
        if (response) {
            await axios.get(config.baseURI + "/api/revision/" + response.data.id + "/indices/download/invoke/",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Token " + cookie.load("token"),
                    },
                });
            await this.getStatus(response.data.id);
        }
    }

    async deleteRevision(id) {
        await new Promise(resolve => this.setState({ confirmDelete: false }, resolve));
        const response = await axios.delete(
            config.baseURI + "/api/revision/" + id,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + cookie.load("token"),
                },
            },
        ).catch(async error => {
            switch (error.response.status) {
                case 401 :
                    await new Promise(resolve => this.setState({ message: "Vous devez être connecté en tant qu'administrateur pour effectuer cette action", icon: "lock" }, resolve));
                    break;
                default :
                    await new Promise(resolve => this.setState({ message: "Impossible de supprimer la révision", icon: "times" }, resolve));
                    break;
            }
            return null;
        });
        if (response) {
            await new Promise(resolve => this.setState({ message: "Révision supprimée avec succès", icon: "check" }, resolve));
            await this.getRevisionList();
        }
    }

    async relaunchRevision(id) {
        await new Promise(resolve => this.setState({ isUploading: true }, resolve));
        await axios.get(config.baseURI + "/api/revision/" + id + "/indices/download/invoke/",
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + cookie.load("token"),
                },
            });
        await this.getStatus(id);
    }

    async activateRevision(id) {
        const response = await axios.patch(
            config.baseURI + "/api/revision/" + id + "/",
            { is_published: true },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + cookie.load("token"),
                },
            },
        ).catch(async error => {
            switch (error.response.status) {
                case 401 :
                    await new Promise(resolve => this.setState({ message: "Vous devez être connecté en tant qu'administrateur pour effectuer cette action", icon: "lock" }, resolve));
                    break;
                default :
                    await new Promise(resolve => this.setState({ message: "Impossible d'activer la révision", icon: "times" }, resolve));
                    break;
            }
            return null;
        });
        if (response) {
            await new Promise(resolve => this.setState({ message: "Révision publiée avec succès", icon: "check" }, resolve));
            await this.getRevisionList();
        }
    }

    render() {
        if (!cookie.load("token")) {
            return (
                <div className="contentAdmin">
                    <div className="actionAdmin"><Trans>Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation</Trans></div>
                </div>
            );
        }
        if (!this.state.isLoading) {
            return (
                <div className="revisionManagementCard">
                    <div className="revisionsCard">
                        <table className="table-striped">
                            <thead>
                                <tr>
                                    {this.state.header.map((item) => {
                                        return (<th key={item}><Trans>{item}</Trans></th>);
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.table.map((item) => {
                                        return (
                                            <tr key={item.date_revision}>
                                                <td>{moment(item.date_revision).format("DD/MM/YYYY HH:mm'ss")}</td>
                                                <td><Trans>{item.state.label}</Trans></td>
                                                <td className="published">
                                                    {item.is_published ? (
                                                        <i className="fa fa-check fa-2x"></i>
                                                    ) : (
                                                        <i className="fa fa-times fa-2x"></i>
                                                    )
                                                    }
                                                </td>
                                                <td className="tdActions">
                                                    <div className="revisionAction" onClick={() => this.confirmDelete(item.id, item.date_revision)}>
                                                        <i className="fa fa-trash fa-1x"></i>
                                                    </div>
                                                    {(item.state.label == "Error") && (
                                                        <div className="revisionAction" onClick={() => this.relaunchRevision(item.id)}>
                                                            <i className="fa fa-redo fa-1x"></i>
                                                        </div>
                                                    )}
                                                    {(!item.is_published && item.state.label == "Available") && (
                                                        <div className="revisionAction" onClick={() => this.activateRevision(item.id)}>
                                                            <i className="fa fa-check fa-1x"></i>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="newRevisionCard">
                        <div className="addRevision hvr-bubble-left" onClick={this.newRevision}>
                            <i className="fa fa-plus fa-1x icon"></i>
                            <span><Trans>Ajouter une nouvelle révision</Trans></span>
                        </div>
                        {this.state.isUploading && (
                            <div className="uploading">
                                <div className="uploadingTitle"><Trans>Remplissage de la nouvelle révision </Trans>{moment().format("DD/MM/YYYY")}</div>
                                <div className="uploadingInfo"><Trans>Patientez s'il vous plaît. L'opération peut prendre plusieurs minutes</Trans></div>
                                <progress value={this.state.progress} max="100">{this.state.progress} %</progress>
                            </div>
                        )}
                        {!this.state.isUploading && (
                            <div className="uploading">
                                <div className="message"><Trans>{this.state.message}</Trans></div>
                                <i className={"fa fa-" + this.state.icon + " fa-4x iconMessage"}></i>
                            </div>
                        )}
                        {this.state.confirmDelete && (
                            <div className="disable">
                                <div className="popUp">
                                    <div className="confirmMessage"><Trans>Etes vous sûr de vouloir supprimer la révision du</Trans>{moment(this.state.itemToDelete.date).format("DD/MM/YYYY HH:mm'ss")} ?</div>
                                    <div className="buttons">
                                        <div className="confirmButton" onClick={() => this.deleteRevision(this.state.itemToDelete.id)}><Trans>Oui</Trans></div>
                                        <div className="confirmButton" onClick={() => this.closePopUp()}><Trans>Non</Trans></div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        return (
            <div className="revisionManagementCard">
                <div className="revisionsCard">
                    <div className="loaderCentered"></div>
                </div>
                <div className="newRevisionCard">
                    <div className="loaderCentered"></div>
                </div>
            </div>
        );
    }
}
