import React from "react";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import config from "../config";
import { Trans } from "react-i18next";

export class Costs extends React.Component {
    render() {
        return (
            <div className="content">
                <Title content="Coûts du projet (en Milliard d'euros)"/>
                <CostsCard/>
            </div>
        );
    }
}

export class CostsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tables: [],
            show: ["hide", "Voir plus", "plus"],
            wbs: [],
            isLoading: true,
            currentWBS: "",
        };
        this.hideShow = this.hideShow.bind(this);
    }

    hideShow() {
        if (this.state.show[0] === "hide") {
            this.setState({ show: ["show", "Voir moins", "minus"] });
        } else {
            this.setState({ show: ["hide", "Voir plus", "plus"] });
        }
    }

    async changeWBS(e) {
        const tables = [];
        let wbsToGet = "";
        if (e) {
            wbsToGet = "?wbs_id=" + e.target.value;
            this.setState({ currentWBS: this.state.wbs.find(x => x.id == e.target.value).date_published });
        }
        const diff = moment().diff(moment("2007-01-01", "YYYY-MM-DD"), "years");
        for (let i = 0; i <= diff; i++) {
            tables["Année " + moment().subtract(i, "year").format("YYYY")] = {
                header: [],
                data: { IT: [], FR: [], TOTAL: [] },
            };
        }
        const responseRevision = await axios.get(config.baseURI + "/api/revision/?is_published=true");
        const response = await axios.get(config.baseURI + "/api/revision/" + responseRevision.data[0].id + "/cost_total_list/" + wbsToGet);
        for (let i = 0; i < response.data.length; i++) {
            tables["Année " + moment(response.data[i].date).format("YYYY")].header.unshift(moment(response.data[i].date).format("MM/YY"));
            Object.keys(response.data[i].cost_values).map((key) => {
                tables["Année " + moment(response.data[i].date).format("YYYY")].data[key].unshift(response.data[i].cost_values[key].toFixed(3));
            });
        }
        Object.keys(tables).map((key) => {
            tables[key].header.unshift("Pays");
            tables[key].data["FR"].unshift("France");
            tables[key].data["IT"].unshift("Italie");
            tables[key].data["TOTAL"].unshift("Total");
        });
        await new Promise(resolve => this.setState({ isLoading: false, tables: tables }, resolve));
    }

    async componentDidMount() {
        const response = await axios.get(`${config.baseURI}/api/wbs/`);
        await new Promise(resolve => this.setState({
            wbs: response.data,
            currentWBS: this.state.wbs.find(x => x.is_default === true).date_published,
        }, resolve));
        await this.changeWBS();
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <div className="costsCard">
                    <div className="WBSCost">
                        <div className="WBSCostText"><Trans>Calculs effectués à partir du WBS du</Trans></div>
                        <select defaultValue={this.state.wbs.find(x => x.is_default === true).id} onChange={(e) => this.changeWBS(e)}>
                            {this.state.wbs.map((item) => {
                                return <option value={item.id} key={item.date_published + item.label}>{moment(item.date_published).format("DD/MM/YYYY") + " - " + item.label}</option>;
                            })}
                        </select>
                    </div>
                    {Object.keys(this.state.tables).map((keyA, indexA) => {
                        if (this.state.tables[keyA].header.length == 1) {
                            return (
                                <div id={"year" + indexA} key={"year" + indexA} className={indexA == 0 ? "yearCost" : "yearCost " + this.state.show[0]}>
                                    <div className="year"><Trans style={{ marginRight: "5px" }}>{keyA.split(" ")[0]}</Trans>{keyA.split(" ")[1]}</div>
                                </div>
                            );
                        } else {
                            return (
                                <div id={"year" + indexA} key={"year" + indexA} className={indexA == 0 ? "yearCost" : "yearCost " + this.state.show[0]}>
                                    <div className="year"><Trans style={{ marginRight: "5px" }}>{keyA.split(" ")[0]}</Trans>{keyA.split(" ")[1]}</div>
                                    <table className="table-striped tableCost">
                                        <thead>
                                            <tr>
                                                {this.state.tables[keyA].header.map((item) => {
                                                    if (item == moment(this.state.currentWBS).format("MM/YY")) {
                                                        return (<th key={item} style={{ backgroundColor: "red" }}><Trans>{item}</Trans></th>);
                                                    } else {
                                                        return (<th key={item}><Trans>{item}</Trans></th>);
                                                    }
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.keys(this.state.tables[keyA].data).map((key) => {
                                                return (
                                                    <tr key={key}>
                                                        {this.state.tables[keyA].data[key].map((item, indexY) => {
                                                            return (<td key={key + " " + indexY}><Trans>{item}</Trans></td>);
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            );
                        }
                    })}
                    <button className="exportButton hvr-bubble-left flex" onClick={this.hideShow}>
                        <i className={"fa fa-" + this.state.show[2] + " fa-1x icon"}></i>
                        <span><Trans>{this.state.show[1]}</Trans></span>
                    </button>
                </div>
            );
        }
        return (
            <div className="costsCard">
                <div className="loaderCentered"></div>
            </div>
        );
    }
}
