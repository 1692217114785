import React from "react";
import { Link } from "react-router";
import { Title } from "./graph";
import { Trans } from "react-i18next";
import cookie from "react-cookies";
import PropTypes from "prop-types";

export class Admin extends React.Component {
    render() {
        if (cookie.load("token")) {
            return (
                <div className="contentAdmin">
                    <Title content="Mode Administrateur"/>
                    <div className="actionAdmin">
                        <ActionCard content="Créer un nouveau WBS" icon="table" link="wbsManagement"/>
                        <ActionCard content="Gérer les révisions" icon="pencil-alt" link="revisionManagement"/>
                        <ActionCard  content="Gérer les index" icon="cogs" link="indexesManagement"/>
                    </div>
                </div>
            );
        }
        return (
            <div className="contentAdmin">
                <div className="actionAdmin">
                    <Trans>Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icône dans le menu de navigation</Trans>
                </div>
            </div>
        );
    }
}

export class ActionCard extends React.Component {
    render() {
        return (
            <Link to={this.props.link} className="actionCard hvr-sweep-to-bottom">
                <i className={"fa fa-" + this.props.icon + " fa-5x adminIcon"}></i>
                <div className="actionName"><Trans>{this.props.content}</Trans></div>
            </Link>
        );
    }
}

ActionCard.propTypes = {
    content: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};
