import React from "react";
import { Link } from "react-router";
import { Trans } from "react-i18next";
import moment from "moment";
import config from "../config";
import axios from "axios";
import cookie from "react-cookies";
import i18next from "i18next";
import PropTypes from "prop-types";

export class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: "",
            pwd: "",
            error: "Saisissez vos identifiants",
            isLoading: false,
        };
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    changeValue(value, e) {
        if (value === "login") {
            this.setState({ login: e.target.value });
        } else {
            this.setState({ pwd: e.target.value });
        }
    }

    async login(e) {
        e.preventDefault();
        await new Promise(resolve => this.setState({ isLoading: true, error: " " }, resolve));
        await axios.post(config.baseURI + "/api/login/",
            {
                username: this.state.login,
                password: this.state.pwd,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            })
            .then(response => {
                cookie.save("token", response.data.token, { secure: false, expires: moment().add(30, "m").toDate() });
                this.props.router.push("/admin");
            })
            .catch(async error => {
                const newState = { isLoading: false };
                switch (error.response.status) {
                    case 401 :
                        newState["error"] = "Login ou mot de passe incorrect";
                        break;
                    default :
                        newState["error"] = "Impossible d'activer la révision";
                        newState["icon"] = "time";
                        break;
                }
                await new Promise(resolve => this.setState(newState, resolve));
            });
    }

    async logout() {
        await new Promise(resolve => this.setState({ isLoading: true }, resolve));
        await axios.post(config.baseURI + "/api/logout/",
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            })
            .then(() => {
                cookie.remove("token");
                this.props.router.push("/login");
                return new Promise(resolve => this.setState({ isLoading: false }, resolve));
            })
            .catch(() => {
                return new Promise(resolve => this.setState({ error: "Problème lors de la déconnexion" }, resolve));
            });
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className="loginContent">
                    <div className="loginTitle"><Trans>Connexion</Trans></div>
                    <div className="loginCard">
                        <div className="loader"></div>
                    </div>
                </div>
            );
        } else {
            if (!cookie.load("token")) {
                return (
                    <div className="loginContent">
                        <div className="loginTitle"><Trans>Connexion</Trans></div>
                        <div className="loginCard">
                            <i className="fa fa-lock fa-5x loginIcon"></i>
                            <div className="error"><Trans>{this.state.error}</Trans></div>
                            <form className="loginForm">
                                <input className="loginInput" type="text" name="login" placeholder={i18next.t("Identifiant")} autoComplete="off" value={this.state.login} onChange={(e) => this.changeValue("login", e)}/>
                                <input className="loginInput" type="password" name="pwd" placeholder={i18next.t("Mot de passe")} value={this.state.pwd} onChange={(e) => this.changeValue("pwd", e)}/>
                                <button type="submit" className="inputSubmit" onClick={this.login}><Trans>Connexion</Trans></button>
                            </form>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="loginContent">
                        <div className="loginTitle"><Trans>Connexion</Trans></div>
                        <div className="loginCard">
                            <i className="fa fa-unlock fa-5x loginIcon"></i>
                            <div className="error"><Trans>Vous êtes déjà authentifié</Trans></div>
                            <Link className="inputSubmit" to="admin"><Trans>Accéder au mode admin</Trans></Link>
                            <button className="inputSubmit" onClick={this.logout}><Trans>Se déconnecter</Trans></button>
                        </div>
                    </div>
                );
            }
        }
    }
}

Login.propTypes = {
    router: PropTypes.object.isRequired,
};
