import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(LanguageDetector)
    .init({
    // we init with resources
        resources: {
            en: {
                translations: {
                    //COMMON
                    "Italie": "Italy",
                    "France": "France",
                    "Total": "Total",
                    "Pays": "Country",
                    //TABLE
                    "Exporter CSV": "Export CSV",
                    "Exporter TELT CSV": "Export TELT CSV",
                    "Voir le graphique": "Show graph",
                    "Voir WBS": "Show WBS",
                    "Voir détails": "Show details",
                    "Voir coûts": "Show costs",
                    "Variation de l'index NLTL": "Variation of NLTL index",
                    "Variation de l'index": "Index variation",
                    "Variation des autres index": "Variation of other indexes",
                    "Var. sur le dernier mois": "Previous month var.",
                    "Var. mensuelle moyenne": "Average monthly var.",
                    "Var. sur un an": "Previous year var.",
                    "Var. annuelle moyenne (au 1° janvier de l'année dernière)": "Average annual var. (from the 1st of January of the current year)",
                    "Var. annuelle moyenne": "Average annual var.",
                    "Var. depuis janvier 2012": "Var. since January 2012",
                    "Var. depuis janvier 2005": "Var. since January 2005",
                    "Var. depuis": "Var. since",
                    "Valeur de l'index NLTL": "NLTL index value",
                    "Valeur de l'index": "Index value",
                    "Estimé": "Estimated",
                    "Provisoire": "Provisional",
                    "Définitif": "Final",
                    // MONTHS
                    "Month 0": "January",
                    "Month 1": "February",
                    "Month 2": "March",
                    "Month 3": "April",
                    "Month 4": "May",
                    "Month 5": "June",
                    "Month 6": "July",
                    "Month 7": "August",
                    "Month 8": "September",
                    "Month 9": "October",
                    "Month 10": "November",
                    "Month 11": "December",
                    "ATTENTION": "WARNING",
                    "Les valeurs en vert sont des valeurs estimées, elles ne sont pas fournies par les sites de référence ISTAT et INSEE": "The values ​​in green are estimated values, they are not provided by the ISTAT and INSEE reference sites",
                    "Les valeurs en vert sont des valeurs estimées, tous les indices de base n'étant pas encore fournis par les sites EUROSTAT, ISTAT et INSEE": "The values ​​in green are estimated values, all the basic indices are not yet provided by the EUROSTAT, ISTAT and INSEE sites.",
                    //GRAPH
                    "Légende": "Key",
                    "Index NLTL": "NLTL index",
                    "Autres index": "Other indexes",
                    "Index NLTL Français": "French NLTL Index",
                    "Index NLTL Italien": "Italian NLTL Index",
                    "Index NLTL Theorique": "Theoretical NLTL Index 1.5 %",
                    "Variation": "Variation",
                    "Voir le détail des index": "See indexes details",
                    "Calculs réalisés en base 100": "Calculations made in base 100",
                    //DETAIL
                    "Détail du calcul des index": "Indexes calculation details",
                    //WBS
                    "Version du projet": "Project version",
                    "Md€ aux conditions économiques de": "Billion € to the economic conditions of",
                    "Génie Civil + environnement + déviations réseaux": "Civil Engineering + environment + network deviations",
                    "Travaux en souterrain": "Underground works",
                    "Travaux de surface": "Surface works",
                    "Equipements + compensation ligne historique": "Equipments + compensation historic line",
                    "Acquisitions foncières et mesures d'accompagnement": "Land acquisitions and accompanying measures",
                    "Management du projet (Promoteur)": "Project Management (Promoter)",
                    //INDEXES
                    "Base de données des index détaillés": "Database of detailed indexes",
                    "Sujets": "Topics",
                    "Sources": "Sources",
                    "Sources / Pays": "Sources / Countries",
                    "Du": "From",
                    "Au": "To",
                    "Dates": "Dates",
                    "IPC hors tabac": "CPI excluding tobacco",
                    "Main d’œuvre / Ingénierie SYNTEC": "Labor / SYNTEC Engineering",
                    "Main d’œuvre / Construction": "Labor / Construction",
                    "Matériel / Machines pour l'extraction ou la construction": "Equipment / Machinery for extraction or construction",
                    "Matériel / Véhicules automobiles": "Material / Motor Vehicles",
                    "Matériaux / CPA Ciment": "Materials / PC Cement",
                    "Matériaux / AG-Sables & graviers d'alluvions": "Materials / AG-Sands & Alluvial gravels",
                    "Matériaux / BCN - Barres crénelées ou nervurées pour béton armé": "Materials / CRB - Crenellated or ribbed bars for reinforced concrete",
                    "Matériaux / Bitume": "Materials / Bitumen",
                    "Matériaux / Pièces de fonderie": "Materials / Castings",
                    "Energie / Gasoil": "Energy / Diesel",
                    "Energie / Electricité": "Energy / Electricity",
                    "Energie / Fioul Domestique": "Energy / Domestic fuel",
                    "Transport": "Transportation",
                    "Frais Divers": "Miscellaneous costs",
                    "Tout sélectionner": "Select all",
                    "usedToBuildTP": "Used to build TP",
                    "usedToBuildTELT": "Used to build TELT indice",
                    //COSTS
                    "Calculs effectués à partir du WBS du": "Calculated from the WBS of :",
                    "Coûts du projet (en Milliard d'euros)": "Project costs (in EUR billion)",
                    "Année": "Year",
                    "Voir plus": "See more",
                    "Voir moins": "Hide",
                    //LOGIN
                    "Saisissez vos identifiants": "Please enter login details",
                    "Login ou mot de passe incorrect": "Incorrect login or password",
                    "Problème lors de la déconnexion": "Trouble logging out",
                    "Vous êtes déjà authentifié": "You are already identified",
                    "Accéder au mode admin": "Access the admin mode",
                    "Se déconnecter": "Sign out",
                    "Connexion": "Log in",
                    "Identifiant": "Login",
                    "Mot de passe": "Password",
                    //ADMIN
                    "Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation": "Please log in as an administrator by clicking on the icon in the navigation menu",
                    "Créer un nouveau WBS": "Create new WBS",
                    "Gérer les révisions": "Revisions Management",
                    "Gérer les index": "Indexes Management",
                    "Mode Administrateur": "Admin mode",
                    //WBS MANAGEMENT
                    "Création d'un Work Breakdown Structure": "Work Breakdown Structure creation",
                    "Nom": "Name",
                    "Vous devez être connecté en tant qu'administrateur pour effectuer cette action": "You must be logged in as administrator to be authorised to do this",
                    "Impossible de créer un nouveau WBS": "Cannot create a new WBS",
                    "Sauvegarder": "Save",
                    "WBS créé avec succès": "WBS successfully created",
                    //REVISION MANAGEMENT
                    "Date de création": "Creation date",
                    "Etat": "State",
                    "Active": "Active",
                    "Actions": "Actions",
                    "Nouvelle révision créée avec succès et publiée": "New revision successfully created and published",
                    "Problème lors la création de la révision. Réessayez plus tard": "Problem while creating the revision. Try again later",
                    "Révision supprimée avec succès": "Review deleted successfully",
                    "Impossible de supprimer la révision": "Can not delete revision",
                    "Révision publiée avec succès": "Revision published successfully",
                    "Impossible de publier la révision": "Can not publish review",
                    "Remplissage de la nouvelle révision": "Provisioning new revision",
                    "Patientez s'il vous plaît. L'opération peut prendre plusieurs minutes": "Please wait, this could take a moment",
                    "Ajouter une nouvelle révision": "Add new revision",
                    "Available": "Available",
                    "Error": "Error",
                    "Provisioning": "Provisioning",
                    "Initialized": "Initialized",
                    "Oui": "Yes",
                    "Non": "No",
                    "Etes vous sûr de vouloir supprimer la révision du": "Are you sure you want to delete the revision of the",
                    //INDEXES MANAGEMENENT
                    "Modifier un index": "Modify index",
                    "Fréquence": "Frequency",
                    "Base": "Base",
                    "Type": "Type",
                    "Pivot": "Pivot",
                    "Actif": "Active",
                    "Impossible de modifier cet index": "Cannot edit this index",
                    "Index modifié avec succès": "Index successfully modified",
                    "Index": "Indexes",
                    "Source": "Source",
                    "Mensuel": "Monthly",
                    "Trimestriel": "Quarterly",
                },
            },
            it: {
                translations: {
                    //COMMON
                    "Italie": "Italia",
                    "France": "Francia",
                    "Total": "Totale",
                    "Pays": "Paese",
                    //TABLE
                    "Exporter CSV": "Esporta CSV",
                    "Exporter TELT CSV": "Esporta TELT CSV",
                    "Voir le graphique": "Visualizza il grafico",
                    "Voir WBS": "Visualizza WBS",
                    "Voir détails": "Visualizza i dettagli",
                    "Voir coûts": "Vedi i costi",
                    "Variation de l'index NLTL": "Variazione dell'indice NLTL",
                    "Variation de l'index": "Variazione dell'indice",
                    "Variation des autres index": "Variazione di altri indici",
                    "Var. sur le dernier mois": "Var. dell'ultimo mese",
                    "Var. mensuelle moyenne": "Var. mensile media",
                    "Var. sur un an": "Var. su un anno",
                    "Var. annuelle moyenne (au 1° janvier de l'année dernière)": "Var. annuale media (al 1° gennaio dell'anno in corso)",
                    "Var. annuelle moyenne": "Var. annuale media",
                    "Var. depuis janvier 2012": "Var. da gennaio 2012",
                    "Var. depuis janvier 2005": "Var. da gennaio 2005",
                    "Var. depuis": "Var. da",
                    "Valeur de l'index NLTL": "Valore dell'indice NLTL",
                    "Valeur de l'index": "Valore dell'indice",
                    "Estimé": "Stimato",
                    "Provisoire": "Provvisorio",
                    "Définitif": "Definitivo",
                    "ATTENTION": "AVVERTIMENTO",
                    "Les valeurs en vert sont des valeurs estimées, elles ne sont pas fournies par les sites de référence ISTAT et INSEE": "I valori in verde sono valori stimati, non sono forniti dai siti di riferimento ISTAT e INSEE",
                    "Les valeurs en vert sont des valeurs estimées, tous les indices de base n'étant pas encore fournis par les sites EUROSTAT, ISTAT et INSEE": "I valori in verde sono valori stimati, perché tutti gli indici di base non sono ancora forniti dai siti EUROSTAT, ISTAT e INSEE",
                    // MONTHS
                    "Month 0": "Gennaio",
                    "Month 1": "Febbraio",
                    "Month 2": "Marzo",
                    "Month 3": "Aprile",
                    "Month 4": "Maggio",
                    "Month 5": "Giugno",
                    "Month 6": "Luglio",
                    "Month 7": "Agosto",
                    "Month 8": "Settembre",
                    "Month 9": "Ottobre",
                    "Month 10": "Novembre",
                    "Month 11": "Dicembre",
                    //GRAPH
                    "Légende": "Leggenda",
                    "Index NLTL": "Indice NLTL",
                    "Autres index": "Altri indici",
                    "Index NLTL Français": "Indice NLTL Francese",
                    "Index NLTL Italien": "Indice NLTL Italiano",
                    "Index NLTL Theorique": "Indice NLTL teorico 1,5 %",
                    "Variation": "Variazione",
                    "Voir le détail des index": "Vedere i dettagli degli indici",
                    "Calculs réalisés en base 100": "Calcoli effettuati in base 100",
                    //DETAIL
                    "Détail du calcul des index": "Dettaglio del calcolo degli indici",
                    //WBS
                    "Version du projet": "Versione del progetto",
                    "Md€ aux conditions économiques de": "M di € alle condizioni economiche di",
                    "Génie Civil + environnement + déviations réseaux": "Ingegneria civile + ambiente + deviazioni di reti",
                    "Travaux en souterrain": "Opere sotterranee",
                    "Travaux de surface": "Lavori in superficie",
                    "Equipements + compensation ligne historique": "Attrezzature + lavori di compensazione della linea storica",
                    "Acquisitions foncières et mesures d'accompagnement": "Acquisizioni di terreni e misure di accompagnamento",
                    "Management du projet (Promoteur)": "Gestione del progetto (promotore)",
                    //INDEXES
                    "Base de données des index détaillés": "Database degli indici dettagliati",
                    "Sujets": "Argomenti",
                    "Sources": "Fonti",
                    "Sources / Pays": "Fonti / Paesi",
                    "Du": "Del",
                    "Au": "A",
                    "Dates": "Date",
                    "IPC hors tabac": "IPL escluso il tabacco",
                    "Main d’œuvre / Ingénierie SYNTEC": "Manodopera / Ingegneria SYNTEC",
                    "Main d’œuvre / Construction": "Manodopera / Costruzione",
                    "Matériel / Machines pour l'extraction ou la construction": "Attrezzature / macchinari per l'estrazione o la costruzione",
                    "Matériel / Véhicules automobiles": "Materiale / veicoli a motore",
                    "Matériaux / CPA Ciment": " Materiale / Cement Portland",
                    "Matériaux / AG-Sables & graviers d'alluvions": "Materiali / sabbie AG e ghiaie alluvionali",
                    "Matériaux / BCN - Barres crénelées ou nervurées pour béton armé": "Materiali / BMN - Barre merlate o nervate per cemento armato",
                    "Matériaux / Bitume": "Materiali / bitume",
                    "Matériaux / Pièces de fonderie": "Materiali / pezzi metallici",
                    "Energie / Gasoil": "Energia / Gasolio",
                    "Energie / Electricité": "Energia / Elettricità",
                    "Energie / Fioul Domestique": "Energia / Combustibile",
                    "Transport": "Trasporto",
                    "Frais Divers": "Spese varie",
                    "Tout sélectionner": "Seleziona tutto",
                    "usedToBuildTP": "Utilizzato soltanto per costruire TP",
                    "usedToBuildTELT": "Utilizzato soltanto per costruire TELT indici",
                    //COSTS
                    "Calculs effectués à partir du WBS du": "Calcoli effettuati dalla WBS del :",
                    "Coûts du projet (en Milliard d'euros)": "Costi del progetto (in miliardi di euro)",
                    "Année": "Anno",
                    "Voir plus": "Vedi di più",
                    "Voir moins": "Vedi di meno",
                    //LOGIN
                    "Saisissez vos identifiants": "Inserisci le tue informazioni di contatto",
                    "Login ou mot de passe incorrect": "Login o password errati",
                    "Problème lors de la déconnexion": "Problemi durante la disconnessione",
                    "Vous êtes déjà authentifié": "Sei già autenticato",
                    "Accéder au mode admin": "Accedi alla modalità amministratore",
                    "Se déconnecter": "Esci",
                    "Connexion": "Entra",
                    "Identifiant": "Login",
                    "Mot de passe": "Password",
                    //ADMIN
                    "Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation": " Effettua il login come amministratore cliccando sull'icona nel menu di navigazione",
                    "Créer un nouveau WBS": "Crea una nuova WBS",
                    "Gérer les révisions": "Gestisci le revisioni",
                    "Gérer les index": "Gestisci indici",
                    "Mode Administrateur": "Modalità amministratore",
                    //WBS MANAGEMENT
                    "Création d'un Work Breakdown Structure": "Creazione di una Work Breakdown Structure",
                    "Nom": "Nome",
                    "Vous devez être connecté en tant qu'administrateur pour effectuer cette action": "È necessario accedere come amministratore per eseguire questa azione",
                    "Impossible de créer un nouveau WBS": "Impossibile creare una nuova WBS",
                    "Sauvegarder": "Salvare",
                    "WBS créé avec succès": "WBS creata con successo",
                    //REVISION MANAGEMENT
                    "Date de création": "Data di creazione",
                    "Etat": "Stato",
                    "Active": "Attivo",
                    "Actions": "Azioni",
                    "Nouvelle révision créée avec succès et publiée": "Nuova revisione creata e pubblicata correttamente",
                    "Problème lors la création de la révision. Réessayez plus tard": "Problema nella creazione della revisione. Si prega di riprovare più tardi",
                    "Révision supprimée avec succès": "Recensione cancellata con successo",
                    "Impossible de supprimer la révision": "Impossibile eliminare la revisione",
                    "Révision publiée avec succès": "Revisione pubblicata con successo",
                    "Impossible de publier la révision": "Impossibile pubblicare la recensione",
                    "Remplissage de la nouvelle révision": "La nuova revisione é in corso di creazione",
                    "Patientez s'il vous plaît. L'opération peut prendre plusieurs minutes": " Per favore aspetta. L'operazione potrebbe richiedere alcuni minuti",
                    "Ajouter une nouvelle révision": "Aggiungi una nuova revisione",
                    "Available": "Disponibile",
                    "Error": "Errore",
                    "Provisioning": "In corso",
                    "Initialized": "Inizializzato",
                    "Oui": "Sì",
                    "Non": "Non",
                    "Etes vous sûr de vouloir supprimer la révision du": "Sei sicuro di voler eliminare la revisione di",
                    //INDEXES MANAGEMENENT
                    "Modifier un index": "Modifica un indice",
                    "Fréquence": "Frequenza",
                    "Base": "Base",
                    "Type": "Tipo",
                    "Pivot": "Pivot",
                    "Actif": "Attivi",
                    "Impossible de modifier cet index": "Impossibile modificare questo indice",
                    "Index modifié avec succès": "Indice modificato con successo",
                    "Index": "Indice",
                    "Source": "Fonte",
                    "Mensuel": "Mensile",
                    "Trimestriel": "Trimestrale",
                },
            },
            fr: {
                translations: {
                    //COMMON
                    "Italie": "Italie",
                    "France": "France",
                    "Total": "Total",
                    "Pays": "Pays",
                    //TABLE
                    "Exporter CSV": "Exporter CSV",
                    "Exporter TELT CSV": "Exporter TELT CSV",
                    "Voir le graphique": "Voir le graphique",
                    "Voir WBS": "Voir WBS",
                    "Voir détails": "Voir détails",
                    "Voir coûts": "Voir coûts",
                    "Variation de l'index NLTL": "Variation de l'index NLTL",
                    "Variation de l'index": "Variation de l'index",
                    "Variation des autres index": "Variation des autres index",
                    "Var. sur le dernier mois": "Var. sur le dernier mois",
                    "Var. mensuelle moyenne": "Var. mensuelle moyenne",
                    "Var. sur un an": "Var. sur un an",
                    "Var. annuelle moyenne (au 1° janvier de l'année dernière)": "Var. annuelle moyenne (au 1° janvier de l'année en cours)",
                    "Var. annuelle moyenne": "Var. annuelle moyenne",
                    "Var. depuis janvier 2012": "Var. depuis janvier 2012",
                    "Var. depuis janvier 2005": "Var. depuis janvier 2005",
                    "Var. depuis": "Var. depuis",
                    "Valeur de l'index NLTL": "Valeur de l'index NLTL",
                    "Valeur de l'index": "Valeur de l'index",
                    "Estimé": "Estimé",
                    "Provisoire": "Provisoire",
                    "Définitif": "Définitif",
                    "ATTENTION": "ATTENTION",
                    "Les valeurs en vert sont des valeurs estimées, elles ne sont pas fournies par les sites de référence ISTAT et INSEE": "Les valeurs en vert sont des valeurs estimées, elles ne sont pas fournies par les sites de référence ISTAT et INSEE",
                    "Les valeurs en vert sont des valeurs estimées, tous les indices de base n'étant pas encore fournis par les sites EUROSTAT, ISTAT et INSEE": "Les valeurs en vert sont des valeurs estimées, tous les indices de base n'étant pas encore fournis par les sites EUROSTAT, ISTAT et INSEE",
                    // MONTHS
                    "Month 0": "Janvier",
                    "Month 1": "Février",
                    "Month 2": "Mars",
                    "Month 3": "Avril",
                    "Month 4": "Mai",
                    "Month 5": "Juin",
                    "Month 6": "Juillet",
                    "Month 7": "Août",
                    "Month 8": "Septembre",
                    "Month 9": "Octobre",
                    "Month 10": "Novembre",
                    "Month 11": "Décembre",
                    //GRAPH
                    "Légende": "Légende",
                    "Index NLTL": "Index NLTL",
                    "Autres index": "Autres index",
                    "Index NLTL Français": "Index NLTL Français",
                    "Index NLTL Italien": "Index NLTL Italien",
                    "Index NLTL Theorique": "Index NLTL théorique 1,5 %",
                    "Variation": "Variation",
                    "Voir le détail des index": "Voir le détail des index",
                    "Calculs réalisés en base 100": "Calculs réalisés en base 100",
                    //DETAIL
                    "Détail du calcul des index": "Détail du calcul des index",
                    //WBS
                    "Version du projet": "Version du projet",
                    "Md€ aux conditions économiques de": "Md€ aux conditions économiques de",
                    "Génie Civil + environnement + déviations réseaux": "Génie Civil + environnement + déviations réseaux",
                    "Travaux en souterrain": "Travaux en souterrain",
                    "Travaux de surface": "Travaux de surface",
                    "Equipements + compensation ligne historique": "Equipements + compensation ligne historique",
                    "Acquisitions foncières et mesures d'accompagnement": "Acquisitions foncières et mesures d'accompagnement",
                    "Management du projet (Promoteur)": "Management du projet (Promoteur)",
                    //INDEXES
                    "Base de données des index détaillés": "Base de données des index détaillés",
                    "Sujets": "Sujets",
                    "Sources": "Sources",
                    "Sources / Pays": "Sources / Pays",
                    "Du": "Du",
                    "Au": "Au",
                    "Dates": "Dates",
                    "IPC hors tabac": "IPC hors tabac",
                    "Main d’œuvre / Ingénierie SYNTEC": "Main d’œuvre / Ingénierie SYNTEC",
                    "Main d’œuvre / Construction": "Main d’œuvre / Construction",
                    "Matériel / Machines pour l'extraction ou la construction": "Matériel / Machines pour l'extraction ou la construction",
                    "Matériel / Véhicules automobiles": "Matériel / Véhicules automobiles",
                    "Matériaux / CPA Ciment": "Matériaux / CPA Ciment",
                    "Matériaux / AG-Sables & graviers d'alluvions": "Matériaux / AG-Sables & graviers d'alluvions",
                    "Matériaux / BCN - Barres crénelées ou nervurées pour béton armé": "Matériaux / BCN - Barres crénelées ou nervurées pour béton armé",
                    "Matériaux / Bitume": "Matériaux / Bitume",
                    "Matériaux / Pièces de fonderie": "Matériaux / Pièces de fonderie",
                    "Energie / Gasoil": "Energie / Gasoil",
                    "Energie / Electricité": "Energie / Electricité",
                    "Energie / Fioul Domestique": "Energie / Fioul Domestique",
                    "Transport": "Transport",
                    "Frais Divers": "Frais Divers",
                    "Tout sélectionner": "Tout sélectionner",
                    "usedToBuildTP": "Utilisé pour la construction du TP",
                    "usedToBuildTELT": "Utilisé pour la construction de l'indice TELT",
                    //COSTS
                    "Calculs effectués à partir du WBS du": "Calculs effectués à partir du WBS du",
                    "Coûts du projet (en Milliard d'euros)": "Coûts du projet (en Milliard d'euros)",
                    "Année": "Année",
                    "Voir plus": "Voir plus",
                    "Voir moins": "Voir moins",
                    //LOGIN
                    "Saisissez vos identifiants": "Saisissez vos identifiants",
                    "Login ou mot de passe incorrect": "Login ou mot de passe incorrect",
                    "Problème lors de la déconnexion": "Problème lors de la déconnexion",
                    "Vous êtes déjà authentifié": "Vous êtes déjà authentifié",
                    "Accéder au mode admin": "Accéder au mode admin",
                    "Se déconnecter": "Se déconnecter",
                    "Connexion": "Connexion",
                    "Identifiant": "Identifiant",
                    "Mot de passe": "Mot de passe",
                    //ADMIN
                    "Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation": "Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation",
                    "Créer un nouveau WBS": "Créer un nouveau WBS",
                    "Gérer les révisions": "Gérer les révisions",
                    "Gérer les index": "Gérer les index",
                    "Mode Administrateur": "Mode Administrateur",
                    //WBS MANAGEMENT
                    "Nom": "Nom",
                    "Vous devez être connecté en tant qu'administrateur pour effectuer cette action": "Vous devez être connecté en tant qu'administrateur pour effectuer cette action",
                    "Impossible de créer un nouveau WBS": "Impossible de créer un nouveau WBS",
                    "Sauvegarder": "Sauvegarder",
                    "WBS créé avec succès": "WBS créé avec succès",
                    //REVISION MANAGEMENT
                    "Date de création": "Date de création",
                    "Etat": "Etat",
                    "Active": "Active",
                    "Actions": "Actions",
                    "Nouvelle révision créée avec succès et publiée": "Nouvelle révision créée avec succès et publiée",
                    "Problème lors la création de la révision. Réessayez plus tard": "Problème lors la création de la révision. Réessayez plus tard",
                    "Révision supprimée avec succès": "Révision supprimée avec succès",
                    "Impossible de supprimer la révision": "Impossible de supprimer la révision",
                    "Révision publiée avec succès": "Révision publiée avec succès",
                    "Impossible de publier la révision": "Impossible de publier la révision",
                    "Remplissage de la nouvelle révision": "Remplissage de la nouvelle révision",
                    "Patientez s'il vous plaît. L'opération peut prendre plusieurs minutes": "Patientez s'il vous plaît. L'opération peut prendre plusieurs minutes",
                    "Ajouter une nouvelle révision": "Ajouter une nouvelle révision",
                    "Available": "Disponible",
                    "Error": "Erreur",
                    "Provisioning": "Remplissage",
                    "Initialized": "Initialisée",
                    "Oui": "Oui",
                    "Non": "Non",
                    "Etes vous sûr de vouloir supprimer la révision du": "Etes vous sûr de vouloir supprimer la révision du",
                    //INDEXES MANAGEMENENT
                    "Modifier un index": "Modifier un index",
                    "Fréquence": "Fréquence",
                    "Base": "Base",
                    "Type": "Type",
                    "Pivot": "Pivot",
                    "Actif": "Actif",
                    "Impossible de modifier cet index": "Impossible de modifier cet index",
                    "Index modifié avec succès": "Index modifié avec succès",
                    "Index": "Index",
                    "Source": "Source",
                    "Mensuel": "Mensuel",
                    "Trimestriel": "Trimestriel",
                },
            },
        },
        fallbackLng: "it",
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ",",
        },
        react: { wait: true },
    });

export default i18n;
