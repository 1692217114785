import React from "react";
import { Trans, translate } from "react-i18next";
import { Link } from "react-router";
import i18n from "../i18n";
import LogoSYSTRA from "../../img/logo_systra.jpg";
import LogoTELT1 from "../../img/logo_telt.png";
import PropTypes from "prop-types";

class Header extends React.Component {
    render() {
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
        };
        return (
            <header className="row header">
                <div className="headerLeft col-md-3">
                    <Link to="/"><img src={LogoTELT1} className="logo" alt="logoTELT"/></Link>
                </div>
                <div className="headerTitle col-md-6"><Trans>Index NLTL</Trans></div>
                <div className="headerRight col-md-3">
                    <Link to="login"><i className="fa fa-user adminLoginBtn"></i></Link>
                    <a
                        href="https://www.systra.com/"
                        className="logoSystraLink"
                        target="_blank"
                        rel='noopener noreferrer'
                    >
                        <img src={LogoSYSTRA} className="logo logoSystra" alt="logoSYSTRA"/>
                    </a>
                    <div className="languages">
                        <div className="language" onClick={() => changeLanguage("it")}>IT</div>
                        <div className="language middle" onClick={() => changeLanguage("en")}>EN</div>
                        <div className="language" onClick={() => changeLanguage("fr")}>FR</div>
                    </div>
                </div>
            </header>
        );
    }
}

class Layout extends React.Component {
    render() {
        return (
            <main>
                <Header/>
                {this.props.children}
            </main>
        );
    }
}

Layout.propTypes = {
    children: PropTypes.object,
};

export default translate("translations")(Layout);
