import React from "react";
import { Line } from "react-chartjs-2";
import { Link } from "react-router";
import moment from "moment";
import axios from "axios";
import data from "./data";
import config from "../config";
import i18n from "../i18n";
import { Trans } from "react-i18next";
import i18next from "i18next";
import cookie from "react-cookies";
import staticData from "./data";
import PropTypes from "prop-types";
import "regenerator-runtime/runtime";

export class Graph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasToken: false,
        };
    }

    componentDidMount() {
        this.setState({ hasToken: cookie.load("token") });
    }

    render() {
        let content;
        let colClass;
        switch (this.state.title) {
            case i18n.t("Variation de l'index NLTL"):
                content = (
                    <div className="legend col-md-3">
                        <LegendCard content={data.chart} />
                        {this.state.hasToken ? <DetailsButton /> : null}
                        <DataCard />
                    </div>
                );
                colClass = "graph col-md-9";
                break;
            case i18n.t("Variation des autres index"):
                content = "";
                colClass = "graph col-md-12";
                break;
            default:
                break;
        }

        return (
            <div className="content">
                <Title content={this.state.title} />
                <div className="row graphSection">
                    {content}
                    <div className={colClass}>
                        <GraphCard content={data.chart} title={this.state.title} />
                    </div>
                </div>
            </div>
        );
    }
}

export class NltlGraph extends Graph {
    constructor(props) {
        super(props);
        this.state.title = i18n.t("Variation de l'index NLTL");
    }
}

export class OthersGraph extends Graph {
    constructor(props) {
        super(props);
        this.state.title = i18n.t("Variation des autres index");
    }
}

export class Title extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="sectionTitle">
                        <div className="back" onClick={() => history.back()}>
                            <i className="fa fa-arrow-left fa-1x icon"></i>
                        </div>
                        <Trans>{this.props.content}</Trans>
                        <div />
                    </div>
                </div>
            </div>
        );
    }
}

Title.propTypes = {
    content: PropTypes.string.isRequired,
};

export class LegendCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentWBS: "" };
    }

    async componentDidMount() {
        const response = await axios.get(`${config.baseURI}/api/wbs/?is_default=true`);
        await new Promise(resolve => this.setState({ currentWBS: moment(response.data[0].date_published).format("YYYY") }, resolve));
    }

    render() {
        return (
            <div className="legendCard">
                <div className="legendTitle">
                    <Trans>Légende</Trans>
                </div>
                <div className="legend-content">
                    <div className="legendRow">
                        <div
                            className="legendColor"
                            style={{ backgroundColor: "rgba(110, 197, 164, 1)" }}
                        ></div>
                        <div className="legendText">
                            <Trans>Index NLTL</Trans>
                        </div>
                    </div>
                    <div className="legendRow">
                        <div
                            className="legendColor"
                            style={{ backgroundColor: "rgba(172, 172, 172, 1)" }}
                        ></div>
                        <div className="legendText">
                            <Trans>Index NLTL Français</Trans>
                        </div>
                    </div>
                    <div className="legendRow">
                        <div
                            className="legendColor"
                            style={{ backgroundColor: "rgba(61, 54, 59, 1)" }}
                        ></div>
                        <div className="legendText">
                            <Trans>Index NLTL Italien</Trans>
                        </div>
                    </div>
                    <div className="legendRow">
                        <div
                            className="legendDottedColor"
                        ></div>
                        <div className="legendText">
                            <Trans>Index NLTL Theorique</Trans>
                        </div>
                    </div>
                    <div className="base">
                        <Trans style={{ marginRight: "5px" }}>
              Calculs réalisés en base 100
                        </Trans>
                        {this.state.currentWBS}
                    </div>
                </div>
            </div>
        );
    }
}

export class DetailsButton extends React.Component {
    render() {
        return (
            <Link to="Detail" className="detailsButton hvr-bubble-right">
                <i className="fa fa-info-circle fa-1x"></i>
                <span style={{ paddingLeft: "20px" }}>
                    <Trans>Voir le détail des index</Trans>
                </span>
            </Link>
        );
    }
}

export class DataCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            isLoading: true,
        };
    }

    async componentDidMount() {
        const values = [];
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const response = await axios.get(`${config.baseURI}/api/revision/${responseRevision.data[0].id}/stats/`);
        values.push({
            name: "Var. sur le dernier mois",
            value: (response.data.stats.var_dernier_mois * 100).toFixed(2),
        });
        values.push({
            name: "Var. mensuelle moyenne",
            value: (response.data.stats.var_mensuelle_moyenne * 100).toFixed(2),
        });
        values.push({
            name: "Var. sur un an",
            value: (response.data.stats.var_dernier_an * 100).toFixed(2),
        });
        values.push({
            name: "Var. annuelle moyenne",
            value: (response.data.stats.var_annuelle_moyenne * 100).toFixed(2),
        });
        values.push({
            name: "Var. depuis janvier 2012",
            value: (response.data.stats.var_depuis_2012 * 100).toFixed(2),
        });
        await new Promise(resolve => this.setState({ values: values, isLoading: false }, resolve));
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <div className="legendCard">
                    <div className="legendTitle">
                        <Trans>Variation</Trans>
                    </div>
                    <div className="legend-content">
                        {this.state.values.map((item, index) => {
                            return (
                                <div className="legendRow" key={"dataRow" + index}>
                                    <Trans>{item.name}</Trans>
                                    <strong style={{ paddingLeft: "5px" }}>
                                        {" : " + item.value + " %"}
                                    </strong>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        }
        return (
            <div className="legendCard">
                <div className="loaderCentered"></div>
            </div>
        );
    }
}

export class GraphCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataToPass: {},
            options: {},
            shouldRedraw: false,
            isLoading: true,
            title: this.props.title,
            height: 120,
        };
    }

    async componentDidMount() {
        switch (this.state.title) {
            case i18n.t("Variation de l'index NLTL"):
                await this.getNltlIndex();
                return new Promise(resolve => this.setState({ height: 130 }, resolve));
            case i18n.t("Variation des autres index"):
                await this.getOtherIndexes();
                return new Promise(resolve => this.setState({ height: 100 }, resolve));
            default:
                return Promise.resolve();
        }
    }

    generateTheoreticalNltlIndice(filteredData) {
        const result = Array();
        let previousValue = 0;
        const annualPercentage = 0.015;
        const monthlyIncrease = Math.pow(1 + annualPercentage, 1 / 12) - 1;
        for (let i = 0; i < filteredData.length; i++) {
            let theoreticalValue = filteredData[i].values.TOTAL;
            if (i > 0) {
                theoreticalValue = previousValue + previousValue * monthlyIncrease;
            }
            previousValue = Math.round(theoreticalValue * 100) / 100;
            result.push(previousValue);
        }
        return result;
    }

    async getNltlIndex() {
        const NLTL = { labels: [], datasets: [] };
        const totalValues = [];
        const italianValues = [];
        const frenchValues = [];
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const response = await axios.get(`${config.baseURI}/api/revision/${responseRevision.data[0].id}/index_total_list/`);
        // Begin the graph from 01/01/2012 (Yves Cottaz 04/24/2023)
        const filteredData = response.data.filter(
            item => moment(item.date) >= moment("01/01/2012", "DD/MM/YYYY"),
        );
        for (let i = 0; i < filteredData.length; i++) {
            NLTL.labels.push(moment(filteredData[i].date).format("MM/YY"));
            totalValues.push(filteredData[i].values.TOTAL);
            italianValues.push(filteredData[i].values.IT);
            frenchValues.push(filteredData[i].values.FR);
        }
        const options = data.defaultOptions;
        options["plugins"]["legend"]["display"] = false;
        NLTL.datasets.push({
            label: i18next.t("Index NLTL"),
            fill: false,
            pointHoverRadius: 5,
            pointRadius: 1,
            pointHitRadius: 10,
            data: totalValues,
            spanGaps: false,
            borderColor: "rgba(110, 197, 164, 1)",
            lineTension: 0,
        });
        NLTL.datasets.push({
            label: i18next.t("Index NLTL Français"),
            fill: false,
            pointHoverRadius: 5,
            pointRadius: 1,
            pointHitRadius: 10,
            data: frenchValues,
            spanGaps: false,
            borderColor: "rgba(172, 172, 172, 1)",
            lineTension: 0,
        });
        NLTL.datasets.push({
            label: i18next.t("Index NLTL Italien"),
            fill: false,
            pointHoverRadius: 5,
            pointRadius: 1,
            pointHitRadius: 10,
            data: italianValues,
            spanGaps: false,
            borderColor: "rgba(61, 54, 59, 1)",
            lineTension: 0,
        });
        const theoreticalNltl = this.generateTheoreticalNltlIndice(filteredData);
        NLTL.datasets.push({
            label: i18next.t("Index NLTL Theorique"),
            fill: false,
            pointHoverRadius: 5,
            pointRadius: 1,
            pointHitRadius: 10,
            data: theoreticalNltl,
            spanGaps: true,
            borderColor: "rgba(110, 197, 164, 1)",
            pointBackgroundColor: ["rgba(110, 197, 164, 1)", "rgba(110, 197, 164, 1)", "rgba(110, 197, 164, 1)"],
            pointBorderColor: ["rgba(110, 197, 164, 1)", "rgba(110, 197, 164, 1)", "rgba(110, 197, 164, 1)"],
            borderWidth: 1,
            lineTension: 0,
            borderDash: [1, 10000],
        });
        await new Promise(resolve => this.setState({
            dataToPass: NLTL,
            options: options,
            isLoading: false,
        }, resolve));
    }

    async getOtherIndexes() {
        const indexData = { labels: [], datasets: [] };
        const options = data.defaultOptions;
        options["plugins"]["legend"]["display"] = true;
        const indexes = [];
        const responseIndexes = await axios.get(`${config.baseURI}/api/indices?is_telt=false`);
        responseIndexes.data.forEach(index => { indexes.push(index); });
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const revisionID = responseRevision.data[0].id;
        const responseParameters = await axios.get(`${config.baseURI}/api/indiceparameters/?is_telt=false`);
        const promises = [];
        indexes.forEach(index => {
            index["paramID"] = responseParameters.data.find(params => params.indice.id == index["id"]).id;
            promises.push(axios.get(`${config.baseURI}/api/revision/${revisionID}/indiceparameter/${index["paramID"]}/values/`));
        });
        const results = await axios.all(promises);
        const minDates = [];
        results.forEach(response => {
            const dates = response.data.map(item => new Date(item.date));
            if (dates.length) {
                minDates.push(new Date(Math.min.apply(null, dates)));
            }
        });
        const lowDateLimit = new Date(Math.max.apply(null, minDates))
            .toISOString()
            .substring(0, 10);
        results.forEach((response, index) => {
            const values = [];
            for (let i = response.data.length - 1; i >= 0; i--) {
                if (moment(response.data[i].date, "YYYY-MM-DD").isAfter(moment(lowDateLimit, "YYYY-MM-DD"))) {
                    values.unshift(response.data[i].value);
                    if (index == 0) {
                        // Set the date labels for the very first indice
                        indexData.labels.unshift(response.data[i].date);
                    }
                }
            }
            indexData.datasets.push({
                label: indexes[index].name,
                fill: false,
                pointHoverRadius: 5,
                pointRadius: 1,
                pointHitRadius: 10,
                data: values,
                spanGaps: false,
                borderColor: staticData.colorList[index],
                lineTension: 0,
            });
        });
        await new Promise(resolve => this.setState({
            dataToPass: indexData,
            options: options,
            isLoading: false,
        }, resolve));
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <div className="graphCard">
                    <Line
                        type="line"
                        data={this.state.dataToPass}
                        options={this.state.options}
                        height={this.state.height}
                    />
                </div>
            );
        }
        return (
            <div className="graphCard">
                <div className="loader"></div>
            </div>
        );
    }
}

GraphCard.propTypes = {
    title: PropTypes.string.isRequired,
};
