import React from "react";
import { CSVLink } from "react-csv";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import config from "../config";
import { Trans, I18n } from "react-i18next";
import cookie from "react-cookies";

export class Indexes extends React.Component {
    render() {
        return (
            <div>
                <Title content="Base de données des index détaillés"/>
                <IndexesCard/>
            </div>
        );
    }
}

export class IndexesCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexes: {},
            data: {},
            dataUsedToBuildTP: {},
            dataUsedToBuildTELT: {},
            isLoading: true,
            labels: [],
            sources: {
                "FR insee": true,
                "FR eurostat": true,
                "IT istat": true,
                "IT eurostat": true,
                "FR systra": true,
                "IT systra": true,
            },
            subjects: {},
            dataRowspan: {},
            from: "2016-01-01",
            to: moment().format("YYYY-MM-DD"),
            totalNbMonthsCol: 0,
            csv: "",
            telt_csv: "",
        };
    }

    monthsDiff(dateFrom, dateTo) {
        let months;
        months = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12;
        months -= dateFrom.getMonth();
        months += dateTo.getMonth();
        return months <= 0 ? 0 : months;
    }

    updateDataRowSpan(sources, data) {
        const dataRowspan = {};
        Object.keys(data).map((key) => {
            let nbRowspan = 0;
            Object.keys(data[key]).map((keyIndex) => {
                if (sources[keyIndex]) {
                    nbRowspan++;
                }
            });
            dataRowspan[key] = nbRowspan;
        });
        return dataRowspan;
    }

    async toggleSource(item) {
        const check = this.state.sources;
        check[item] = !check[item];
        await new Promise(resolve => this.setState({
            sources: check,
            dataRowspan: this.updateDataRowSpan(check, this.state.data),
        }, resolve));
    }

    async toggleSubject(item) {
        const check = this.state.subjects;
        check[item.target.value] = !check[item.target.value];
        await new Promise(resolve => this.setState({ subjects: check }, resolve));
    }

    async selectAll(item) {
        const check = this.state.subjects;
        Object.keys(check).map((key) => {
            check[key] = item.target.checked;
        });
        await new Promise(resolve => this.setState({ subjects: check }, resolve));
    }

    async changeDate(item, name) {
        let moment_from = this.state.from;
        let moment_to = this.state.to;
        if (name == "from") {
            if (item.target.valueAsDate >= new Date(2005, 12, 1)) {
                moment_from = item.target.value;
            }
        } else {
            moment_to = item.target.value;
        }
        const totalNbMonthsCol = this.getTotalNbMonthsCol(this.state.labels, moment_from, moment_to);
        await new Promise(resolve => this.setState({
            from: moment_from,
            to: moment_to,
            totalNbMonthsCol,
        }, resolve));
    }

    getTotalNbMonthsCol(labels, moment_from, moment_to) {
        const totalNbMonthsCol = labels.filter(
            (item, index) => index > 1 && moment(item).isSameOrAfter(moment(moment_from)) && moment(item).isBefore(moment(moment_to)),
        ).length;
        return totalNbMonthsCol;
    }

    async exportCSV(revisionID) {
        const promises = [];
        promises.push(axios.get(config.baseURI + "/api/revision/" + revisionID + "/indicevalues/export/"));
        promises.push(axios.get(config.baseURI + "/api/revision/" + revisionID + "/indicevalues/telt/export/"));
        const results = await axios.all(promises);
        let indice_csv = null;
        let indice_telt_csv = null;
        results.forEach((response, index) => {
            switch (index) {
                case 0:
                    indice_csv = response.data;
                    break;
                case 1:
                    indice_telt_csv = response.data;
                    break;
                default:
                    break;
            }
        });
        await new Promise(resolve => this.setState({ csv: indice_csv, telt_csv: indice_telt_csv }, resolve));
    }

    setTPFlag(finalResult, category, minDate) {
        Object.keys(finalResult[category]).map((source) => {
            switch (source) {
                case "FR systra":
                    Object.keys(finalResult[category][source]).map((key) => {
                        if (moment(finalResult[category][source][key].date).isSameOrAfter(minDate["FR"])) {
                            finalResult[category][source][key].flag = "S";
                        }
                    });
                    break;
                case "IT systra":
                    Object.keys(finalResult[category][source]).map((key) => {
                        if (moment(finalResult[category][source][key].date).isSameOrAfter(minDate["IT"])) {
                            finalResult[category][source][key].flag = "S";
                        }
                    });
                    break;
                default:
                    break;
            }
        });
    }

    findAndSetTPFlag(finalResult, hashTP01indiceParameterID, hashTP05bindiceParameterID, rows) {
        // Find extrapolated min date in indices data used to build TP01 and TP05b FR/IT
        const countries = ["FR", "IT"];
        const minDateTP01 = {};
        const minDateTP05b = {};
        minDateTP01["FR"] = null;
        minDateTP01["IT"] = null;
        minDateTP05b["FR"] = null;
        minDateTP05b["IT"] = null;
        for (const countryIdx in countries) {
            const country = countries[countryIdx];
            Object.keys(hashTP01indiceParameterID[country]).map((key) => {
                Object.keys(finalResult[rows[key].category][rows[key].source]).map((keyz) => {
                    if (finalResult[rows[key].category][rows[key].source][keyz].flag) {
                        if (!minDateTP01[country] || moment(finalResult[rows[key].category][rows[key].source][keyz].date).isBefore(minDateTP01[country])) {
                            minDateTP01[country] = moment(finalResult[rows[key].category][rows[key].source][keyz].date);
                        }
                    }
                });
            });
            Object.keys(hashTP05bindiceParameterID[country]).map((key) => {
                Object.keys(finalResult[rows[key].category][rows[key].source]).map((keyz) => {
                    if (finalResult[rows[key].category][rows[key].source][keyz].flag) {
                        if (!minDateTP05b[country] || moment(finalResult[rows[key].category][rows[key].source][keyz].date).isBefore(minDateTP05b[country])) {
                            minDateTP05b[country] = moment(finalResult[rows[key].category][rows[key].source][keyz].date);
                        }
                    }
                });
            });
        }
        // Set TP flags
        this.setTPFlag(finalResult, "TP01", minDateTP01);
        this.setTPFlag(finalResult, "TP05b", minDateTP05b);
    }

    async componentDidMount() {
        const responseRevision = await axios.all([
            axios.get(config.baseURI + "/api/revision/?is_published=true"),
            axios.get(config.baseURI + "/api/wbs/?is_defalt=true"),
        ]);
        const revisionID = responseRevision[0].data[0].id;
        const wbsID = responseRevision[1].data[0].id;
        const wbsIndiceParametersUsed = await axios.get(config.baseURI + "/api/wbs/" + wbsID + "/index_used_list/");
        await this.exportCSV(revisionID);
        const results = await axios.all([
            axios.get(config.baseURI + "/api/revision/" + revisionID + "/indiceparameters/"),
            axios.get(config.baseURI + "/api/revision/" + revisionID + "/indiceparameters/?used_to_build_tp=true"),
            axios.get(config.baseURI + "/api/pww/"),
        ]);
        const allIndiceParameters = results[0];
        const TPIndiceParameters = results[1];
        const pwwIndiceParameters = results[2];
        const indexesList = [];
        for (let j = 0; j < allIndiceParameters.data.length; j++) {
            if (allIndiceParameters.data[j].country.id === 1 && (allIndiceParameters.data[j].provider.id === 1 || allIndiceParameters.data[j].provider.id === 4)) {
                indexesList.push({ name: allIndiceParameters.data[j].indice.name, id: allIndiceParameters.data[j].id, checked: false });
            }
        }
        // Indices used to build TELT indice
        const hashTELTindiceParameterID = {};
        wbsIndiceParametersUsed.data.forEach((e) => {
            hashTELTindiceParameterID[e.id] = true;
        });
        // Indices used to build TP hash
        const hashTPindiceParameterID = {};
        TPIndiceParameters.data.forEach((e) => {
            hashTPindiceParameterID[e.id] = true;
        });
        // Indices used to build TP01 and TP05b FR/IT
        const hashTP01indiceParameterID = {};
        const hashTP05bindiceParameterID = {};
        pwwIndiceParameters.data.forEach(e => {
            switch (e.indice_parameters_public_work.indice.name) {
                case "TP01":
                    if (!(e.indice_parameters_public_work.country.name in hashTP01indiceParameterID)) {
                        hashTP01indiceParameterID[e.indice_parameters_public_work.country.name] = {};
                    }
                    hashTP01indiceParameterID[e.indice_parameters_public_work.country.name][e.indice_parameters_indice.id] = true;
                    break;
                case "TP05b":
                    if (!(e.indice_parameters_public_work.country.name in hashTP05bindiceParameterID)) {
                        hashTP05bindiceParameterID[e.indice_parameters_public_work.country.name] = {};
                    }
                    hashTP05bindiceParameterID[e.indice_parameters_public_work.country.name][e.indice_parameters_indice.id] = true;
                    break;
                default:
                    break;
            }
        });
        const promises = [];
        const rows = {};
        allIndiceParameters.data.forEach(e => {
            rows[e.id] = {
                category: e.indice.name,
                source: e.country.name + " " + e.provider.name,
                data: [],
                isUsedToBuildTP: hashTPindiceParameterID[e.id],
                isUsedToBuildTELT: hashTELTindiceParameterID[e.id],
            };
            const url = config.baseURI + "/api/revision/" + revisionID + "/indiceparameter/" + e.id + "/values/";
            promises.push(axios.get(url));
        });
        const results2 = await axios.all(promises);
        const labels = [];
        results2.forEach((response, index) => {
            const s = response.request.responseURL;
            const start_pos = s.indexOf("indiceparameter/") + 16;
            const end_pos = s.indexOf("/values", start_pos);
            const text_to_get = s.substring(start_pos, end_pos);
            response.data.forEach(line => {
                rows[text_to_get].data.unshift({ date: line.date, value: line.value, flag: line.flag });
                if (index == 0) {
                    labels.unshift(moment(line.date).format("YYYY-MM-DD"));
                }
            });
        });
        labels.unshift("Sources");
        labels.unshift("Sujets");
        const finalResult = {};
        const finalResultUsedToBuildTP = {};
        const finalResultUsedToBuildTELT = {};
        const subjects = {};
        Object.keys(rows).map(key => {
            if (rows[key].category != "TP01 INSEE" && rows[key].category != "TP05b INSEE") {
                finalResult[rows[key].category] = {};
                finalResultUsedToBuildTP[rows[key].category] = {};
                finalResultUsedToBuildTELT[rows[key].category] = {};
            }
            if (["TP01", "TP05b", "TP01 INSEE", "TP05b INSEE", "IPC hors tabac", "Main d’œuvre / Ingénierie SYNTEC"].indexOf(rows[key].category) > -1) {
                subjects[rows[key].category] = true;
            } else {
                subjects[rows[key].category] = false;
            }
        });
        Object.keys(rows).map(key => {
            switch (rows[key].category) {
                case "TP01 INSEE":
                    finalResult["TP01"]["FR insee"] = rows[key].data;
                    finalResultUsedToBuildTP["TP01"]["FR insee"] = rows[key].isUsedToBuildTP;
                    finalResultUsedToBuildTELT["TP01"]["FR insee"] = rows[key].isUsedToBuildTELT;
                    break;
                case "TP05b INSEE":
                    finalResult["TP05b"]["FR insee"] = rows[key].data;
                    finalResultUsedToBuildTP["TP05b"]["FR insee"] = rows[key].isUsedToBuildTP;
                    finalResultUsedToBuildTELT["TP01"]["FR insee"] = rows[key].isUsedToBuildTELT;
                    break;
                default:
                    finalResult[rows[key].category][rows[key].source] = rows[key].data;
                    finalResultUsedToBuildTP[rows[key].category][rows[key].source] = rows[key].isUsedToBuildTP;
                    finalResultUsedToBuildTELT[rows[key].category][rows[key].source] = rows[key].isUsedToBuildTELT;
            }
        });
        this.findAndSetTPFlag(finalResult, hashTP01indiceParameterID, hashTP05bindiceParameterID, rows);
        const totalNbMonthsCol = this.getTotalNbMonthsCol(labels, this.state.from, this.state.to);
        await new Promise(resolve => this.setState({
            totalNbMonthsCol,
            indexes: indexesList,
            isLoading: false,
            data: finalResult,
            dataUsedToBuildTP: finalResultUsedToBuildTP,
            dataUsedToBuildTELT: finalResultUsedToBuildTELT,
            labels: labels,
            subjects: subjects,
            dataRowspan: this.updateDataRowSpan(this.state.sources, finalResult),
        }, resolve));
        setTimeout(() => { this.enableTableScroll(); }, 100);
    }

    enableTableScroll() {
        const fcBody = document.querySelector(".fix-column > .tbody");
        const rcBody = document.querySelector(".rest-columns > .tbody");
        const rcHead = document.querySelector(".rest-columns > .thead");
        if (rcBody) {
            rcBody.addEventListener("scroll", function () {
                fcBody.scrollTop = this.scrollTop;
                rcHead.scrollLeft = this.scrollLeft;
            }, { passive: true });
        } else {
            setTimeout(() => { this.enableTableScroll(); }, 100);
        }
    }

    render() {
        if (cookie.load("token")) {
            if (!this.state.isLoading) {
                return (
                    <I18n>
                        {(t, { _i18n }) => (
                            <div className="indexesCard">
                                <div className="indexesCardParameters">
                                    <div className="parametersTitle"><Trans>Sujets</Trans></div>
                                    <div className="parametersContentSelect" >
                                        <div className="parametersRow">
                                            <input type="checkbox" name="indexes" value="0" onChange={(e) => this.selectAll(e)}/>
                                            <span className="parametersRowText bold">
                                                <Trans>Tout sélectionner</Trans>
                                            </span>
                                        </div>
                                        {this.state.indexes.map((item) => {
                                            return (
                                                <div className="parametersRow" key={"row" + item.id}>
                                                    <input type="checkbox" name="indexes" value={item.name} key={item.id} checked={this.state.subjects[item.name]} onChange={(e) => this.toggleSubject(e)}/>
                                                    <span className="parametersRowText" key={"text" + item.id}>
                                                        <Trans>{item.name}</Trans>
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className="parametersTitle"><Trans>Sources / Pays</Trans></div>
                                    <div className="parametersContent">
                                        <div className="parametersRow">
                                            <div className="country">FR</div>
                                            <input type="checkbox" name="country" value="inseeFR" checked={this.state.sources["FR insee"]} onChange={this.toggleSource.bind(this, "FR insee")}/>
                                            <span className="inputCountry">Insee</span>
                                            <input type="checkbox" name="country" value="EurostatFR" checked={this.state.sources["FR eurostat"]} onChange={this.toggleSource.bind(this, "FR eurostat")}/>
                                            <span className="inputCountry">Eurostat</span>
                                        </div>
                                        <div className="parametersRow">
                                            <div className="country">IT</div>
                                            <input type="checkbox" name="country" value="istatIT" checked={this.state.sources["IT istat"]} onChange={this.toggleSource.bind(this, "IT istat")}/>
                                            <span className="inputCountry">Istat</span>
                                            <input type="checkbox" name="country" value="EurostatIT" checked={this.state.sources["IT eurostat"]} onChange={this.toggleSource.bind(this, "IT eurostat")}/>
                                            <span className="inputCountry">Eurostat</span>
                                        </div>
                                        <div className="parametersRow">
                                        </div>
                                    </div>
                                    <div className="parametersTitle"><Trans>Dates</Trans></div>
                                    <div className="parametersContent">
                                        <div className="parametersRow rangeRow">
                                            <label className="bold"><Trans>Du</Trans></label>
                                            <input type="date" name="from" className="selectRange" value={this.state.from} min="2006-01-01" onChange={(e) => this.changeDate(e, "from")}/>
                                        </div>
                                        <div className="parametersRow rangeRow">
                                            <label className="bold"><Trans>Au</Trans></label>
                                            <input type="date" name="to" className="selectRange" value={this.state.to} onChange={(e) => this.changeDate(e, "to")}/>
                                        </div>
                                    </div>
                                    <CSVLink data={this.state.csv} separator={";"} filename={"Indexes NLTL.csv"} className="exportButton hvr-bubble-right flex indexesButton">
                                        <i className="fa fa-file-excel fa-1x icon"></i>
                                        <span><Trans>Exporter CSV</Trans></span>
                                    </CSVLink>
                                    <CSVLink data={this.state.telt_csv} separator={";"} filename={"Indexes TELT.csv"} className="exportButton hvr-bubble-right flex indexesButton">
                                        <i className="fa fa-file-excel fa-1x icon"></i>
                                        <span><Trans>Exporter TELT CSV</Trans></span>
                                    </CSVLink>
                                </div>
                                <div className="indexesCardData">
                                    <div className="total-wrapper">
                                        <div className="fix-column">
                                            <div className="thead">
                                                {
                                                    this.state.labels.map((item, index) => {
                                                        let header = null;
                                                        switch (index) {
                                                            case 0:
                                                                header = <span className="tdIndexes" key={item}><Trans>{item}</Trans></span>;
                                                                break;
                                                            case 1:
                                                                header = <span className="tdSourceIndexes" key={item}><Trans>{item}</Trans></span>;
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                        return header;
                                                    })
                                                }
                                            </div>
                                            <div className="tbody">
                                                {
                                                    Object.keys(this.state.data).map((key, _index) => {
                                                        let isFirstRow = true;
                                                        let dataRow = null;
                                                        if (this.state.subjects[key]) {
                                                            let idxRow = -1;
                                                            let nbRowsToDisplay = 0;
                                                            Object.keys(this.state.data[key]).map((keyY) => {
                                                                if (this.state.sources[keyY]) {
                                                                    nbRowsToDisplay++;
                                                                }
                                                            });
                                                            let firstColIdx = Math.round(nbRowsToDisplay / 2) - 1;
                                                            if (firstColIdx < 0) {
                                                                firstColIdx = 0;
                                                            }
                                                            dataRow = (
                                                                Object.keys(this.state.data[key]).map((keyY) => {
                                                                    let dataKeyRow = null;
                                                                    if (this.state.sources[keyY]) {
                                                                        const boldRow = isFirstRow ? "boldRow " : "";
                                                                        const keyToDisplay = t(key).length < 30 ? t(key) : t(key).substring(0, 29) + "...";
                                                                        isFirstRow = false;
                                                                        idxRow++;
                                                                        dataKeyRow = (
                                                                            <div className="trow" key={key + " " + keyY + " div" + idxRow}>
                                                                                {
                                                                                    (idxRow == firstColIdx) &&
                                                                                    <span className={boldRow + "tdIndexes"} key={key + " " + keyY + " subject" + idxRow} title={t(key)}>{keyToDisplay}</span>
                                                                                }
                                                                                {
                                                                                    (idxRow != firstColIdx) &&
                                                                                    <span className={boldRow + "tdIndexes"} key={key + " " + keyY + " subject" + idxRow}>&nbsp;</span>
                                                                                }
                                                                                {
                                                                                    !this.state.dataUsedToBuildTP[key][keyY] && !this.state.dataUsedToBuildTELT[key][keyY] &&
                                                                                    <span className={boldRow + "tdSourceIndexes"} key={key + " " + keyY + " source" + idxRow}>
                                                                                        {keyY}
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    this.state.dataUsedToBuildTP[key][keyY] &&
                                                                                    <span className={boldRow + "tdSourceIndexes usedToBuildIndices"} key={key + " " + keyY + " source" + idxRow}>
                                                                                        {keyY}
                                                                                        <img style={{ float: "right", verticalAlign: "middle" }} src="img/build.png" width="16" height="16" title={t("usedToBuildTP")} />
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    this.state.dataUsedToBuildTELT[key][keyY] &&
                                                                                    <span className={boldRow + "tdSourceIndexes usedToBuildIndices"} key={key + " " + keyY + " source" + idxRow}>
                                                                                        {keyY}
                                                                                        <img style={{ float: "right", verticalAlign: "middle" }} src="img/logo_telt.png" width="16" height="8" title={t("usedToBuildTELT")} />
                                                                                    </span>
                                                                                }
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return dataKeyRow;
                                                                })
                                                            );
                                                        }
                                                        return dataRow;
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="rest-columns">
                                            <div className="thead">
                                                {
                                                    this.state.labels.map((item, index) => {
                                                        if (index > 1 && moment(item).isSameOrAfter(moment(this.state.from)) && moment(item).isBefore(moment(this.state.to))) {
                                                            return <span className="monthIndexes" key={item}><Trans>{moment(item).format("MM/YY")}</Trans></span>;
                                                        }
                                                        return null;
                                                    })
                                                }
                                            </div>
                                            <div className="tbody">
                                                {
                                                    Object.keys(this.state.data).map((key, _index) => {
                                                        let isFirstRow = true;
                                                        let dataRow = null;
                                                        if (this.state.subjects[key]) {
                                                            dataRow = (
                                                                Object.keys(this.state.data[key]).map(keyY => {
                                                                    let dataKeyRow = null;
                                                                    let nbColMonths = 0;
                                                                    if (this.state.sources[keyY]) {
                                                                        const boldRow = isFirstRow ? " boldRow" : "";
                                                                        isFirstRow = false;
                                                                        dataKeyRow = [];
                                                                        Object.keys(this.state.data[key][keyY]).map(keyZ => {
                                                                            let monthCol = null;
                                                                            if (moment(this.state.data[key][keyY][keyZ].date).isSameOrAfter(moment(this.state.from)) &&
                                                                                moment(this.state.data[key][keyY][keyZ].date).isBefore(moment(this.state.to))) {
                                                                                if (this.state.data[key][keyY][keyZ].flag) {
                                                                                    monthCol = (
                                                                                        <span className={(this.state.dataUsedToBuildTP[key][keyY] || this.state.dataUsedToBuildTELT[key][keyY]) ? "monthIndexes tempValue usedToBuildIndices" + boldRow : "monthIndexes tempValue" + boldRow} key={key + " " + keyY + " " + this.state.data[key][keyY][keyZ].date}>
                                                                                            {this.state.data[key][keyY][keyZ].value}
                                                                                        </span>
                                                                                    );
                                                                                } else {
                                                                                    monthCol = (
                                                                                        <span className={(this.state.dataUsedToBuildTP[key][keyY] || this.state.dataUsedToBuildTELT[key][keyY]) ? "monthIndexes usedToBuildIndices" + boldRow : "monthIndexes" + boldRow} key={key + " " + keyY + " " + this.state.data[key][keyY][keyZ].date}>
                                                                                            {this.state.data[key][keyY][keyZ].value}
                                                                                        </span>
                                                                                    );
                                                                                }
                                                                                nbColMonths++;
                                                                                dataKeyRow.push(monthCol);
                                                                            }
                                                                        });
                                                                        // Complete current row with "empty" cells if needed
                                                                        if (this.state.totalNbMonthsCol > nbColMonths) {
                                                                            const endEmptyRow = Array(this.state.totalNbMonthsCol - nbColMonths).fill().map((_, i) => {
                                                                                return (
                                                                                    <span className={(this.state.dataUsedToBuildTP[key][keyY] || this.state.dataUsedToBuildTELT[key][keyY]) ? "monthIndexes usedToBuildIndices" + boldRow : "monthIndexes" + boldRow} key={key + " " + keyY + " " + i + " span"}>-</span>
                                                                                );
                                                                            });
                                                                            dataKeyRow = dataKeyRow.concat(endEmptyRow);
                                                                        }
                                                                    }
                                                                    return (
                                                                        <div className="trow" key={key + " " + keyY + nbColMonths}>{ dataKeyRow }</div>
                                                                    );
                                                                })
                                                            );
                                                        }
                                                        return dataRow;
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </I18n>
                );
            }
            return (
                <div className="WBSCard">
                    <div className="loaderCentered"></div>
                </div>
            );
        } else {
            return (
                <div className="contentAdmin">
                    <div className="actionAdmin"><Trans>Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation</Trans></div>
                </div>
            );
        }
    }
}
