module.exports = {
    defaultOptions: {
        plugins: {
            legend: {
                display: true,
                position: "left",
                labels: {
                    padding: 20,
                    boxWidth: 20,
                },
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: "y",
                },
                limits: {
                    y: {
                        min: 70,
                        max: 130,
                    },
                },
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                },
            },
            tooltip: {
                enabled: true,
            },
        },
    },
    colorList: [
        "rgba(69, 3, 41, 1)",
        "rgba(207, 70, 149, 1)",
        "rgba(0, 34, 69, 1)",
        "rgba(62, 133, 207, 1)",
        "rgba(12, 69, 28, 1)",
        "rgba(98, 207, 127, 1)",
        "rgba(69, 6, 0, 1)",
        "rgba(207, 75, 62, 1)",
        "rgba(69, 63, 69, 1)",
        "rgba(207, 191, 207, 1)",
    ],
};
