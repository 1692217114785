import React from "react";
import { Link } from "react-router";
import { CSVLink } from "react-csv";
import axios from "axios";
import moment from "moment";
import config from "../config";
import { Trans } from "react-i18next";
import i18n from "../i18n";
import ImageGraph from "../../img/graph.png";
import ImageGraphW from "../../img/graphW.png";
import cookie from "react-cookies";
import PropTypes from "prop-types";
import "regenerator-runtime/runtime";

const PAGES = {
    NTL_INDEXES: "ntl_indexes",
    OTHER_INDEXES: "other_indexes",
};

export class ButtonGraph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgSrc: ImageGraph,
            toPage: props.toPage,
        };
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver() {
        this.setState({ imgSrc: ImageGraphW });
    }

    handleMouseOut() {
        this.setState({ imgSrc: ImageGraph });
    }

    render() {
        return (
            <Link to={this.state.toPage} className="exportButton hvr-bubble-left flex" onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                <i className="fa fa-chart-line fa-1x icon"></i>
                <span><Trans>Voir le graphique</Trans></span>
            </Link>
        );
    }
}

ButtonGraph.propTypes = {
    toPage: PropTypes.string.isRequired,
};

export class TabBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props.page,
        };
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            page: nextProps.page,
        };
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <div className="tabBar">
                        <button
                            className={"tabItemLeft " + (this.state.page === PAGES.NTL_INDEXES ? "activeTabItem" : "")}
                            onClick={() => this.props.changePage(PAGES.NTL_INDEXES)}
                        >
                            <Trans>Index NLTL</Trans>
                        </button>
                        <button
                            className={"tabItemRight " + (this.state.page === PAGES.OTHER_INDEXES ? "activeTabItem" : "")}
                            onClick={() => this.props.changePage(PAGES.OTHER_INDEXES)}
                        >
                            <Trans>Autres index</Trans>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

TabBar.propTypes = {
    page: PropTypes.string.isRequired,
    changePage: PropTypes.func.isRequired,
};

export class TableNltlStatsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            isLoading: true,
        };
    }

    async componentDidMount() {
        const values = [];
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const revisionID = responseRevision.data[0].id;
        const response = await axios.get(`${config.baseURI}/api/revision/${revisionID}/stats/`);
        values.push({ name: "Var. sur le dernier mois", value: (response.data.stats.var_dernier_mois * 100).toFixed(2) });
        values.push({ name: "Var. mensuelle moyenne", value: (response.data.stats.var_mensuelle_moyenne * 100).toFixed(2) });
        values.push({ name: "Var. sur un an", value: (response.data.stats.var_dernier_an * 100).toFixed(2) });
        values.push({ name: "Var. annuelle moyenne (au 1° janvier de l'année dernière)", value: (response.data.stats.var_annuelle_moyenne * 100).toFixed(2) });
        values.push({ name: "Var. depuis janvier 2012", value: (response.data.stats.var_depuis_2012 * 100).toFixed(2) });
        await new Promise(resolve => this.setState({ values: values, isLoading: false }, resolve));
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <section className="row card flex">
                    <div className="section-header"><Trans>Variation de l'index NLTL</Trans></div>
                    <table className="table-striped indexStatsTable">
                        <tbody>
                            {this.state.values.map(item => {
                                return (
                                    <tr key={item.name}>
                                        <td className="tdLarge"><Trans>{item.name}</Trans></td>
                                        <td className="tdSmall">{item.value + " %"}</td>
                                    </tr>
                                );
                            }, this)}
                        </tbody>
                    </table>
                    <div className="actions">
                        <CSVLink data={this.state.values} separator={";"} filename={"Variation of NLTL index.csv"} className="exportButton hvr-bubble-left flex">
                            <i className="fas fa-file-csv fa-1x icon"></i>
                            <span><Trans>Exporter CSV</Trans></span>
                        </CSVLink>
                    </div>
                </section>
            );
        }
        return (
            <section className="row card flex">
                <div className="loaderCentered"></div>
            </section>
        );
    }
}

export class TableNltlValueCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            values_csv: [],
            hasToken: false,
            isLoading: true,
        };
    }

    async componentDidMount() {
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const values = [];
        const values_csv = [];
        const revisionID = responseRevision.data[0].id;
        const response = await axios.get(`${config.baseURI}/api/revision/${revisionID}/index_total_list/`);
        for (let i = response.data.length - 1; i >= 0; i--) {
            if (moment(response.data[i].date).isSameOrAfter("2012-01-01", "day")) {
                values.push({
                    date: response.data[i].date,
                    index_nltl: (response.data[i].values.TOTAL).toFixed(2),
                    index_nltl_fr: (response.data[i].values.FR).toFixed(2),
                    index_nltl_it: (response.data[i].values.IT).toFixed(2),
                    nltl_etat_valeur: response.data[i].values.TOTAL_FLAG,
                    nltl_fr_etat_valeur: response.data[i].values.FR_FLAG,
                    nltl_it_etat_valeur: response.data[i].values.IT_FLAG,
                });
                values_csv.push({
                    date: response.data[i].date,
                    index_nltl: (response.data[i].values.TOTAL).toFixed(2),
                    index_nltl_fr: (response.data[i].values.FR).toFixed(2),
                    index_nltl_it: (response.data[i].values.IT).toFixed(2),
                    nltl_etat_valeur: this.getCsvState(response.data[i].values.TOTAL_FLAG),
                    nltl_fr_etat_valeur: this.getCsvState(response.data[i].values.FR_FLAG),
                    nltl_it_etat_valeur: this.getCsvState(response.data[i].values.IT_FLAG),
                });
            }
        }
        await new Promise(resolve => this.setState({ values: values, values_csv: values_csv, hasToken: cookie.load("token"), isLoading: false }, resolve));
    }

    getCsvState(flag, to_translate = true) {
        switch (flag) {
            case "E":
                return to_translate ? i18n.t("Estimé") : "Estimé";
            case "D":
                return to_translate ? i18n.t("Définitif") : "Définitif";
            case "P":
                return to_translate ? i18n.t("Provisoire") : "Provisoire";
            default:
                return "";
        }
    }

    getNltlClass(flag) {
        switch (flag) {
            case "E":
                return "nltl_e ";
            case "D":
                return "nltl_d ";
            case "P":
                return "nltl_p ";
            default:
                return "";
        }
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <section className="row card flex">
                    <div className="section-header"><Trans>Valeur de l'index NLTL</Trans></div>
                    <div className="tableContainer">
                        <table className="table-striped indexTable">
                            <thead>
                                <tr>
                                    <th><Trans>Date</Trans></th>
                                    <th><Trans>NLTL</Trans></th>
                                    <th><Trans>NLTL FR</Trans></th>
                                    <th><Trans>NLTL IT</Trans></th>
                                </tr>
                            </thead>
                            <tbody className="scrollTableBody">
                                {this.state.values.map(item => {
                                    const nltl_bg_color = this.getNltlClass(item.nltl_etat_valeur);
                                    const nltl_fr_bg_color = this.getNltlClass(item.nltl_fr_etat_valeur);
                                    const nltl_it_bg_color = this.getNltlClass(item.nltl_it_etat_valeur);
                                    const nltl_title = this.getCsvState(item.nltl_etat_valeur);
                                    const nltl_fr_title = this.getCsvState(item.nltl_fr_etat_valeur);
                                    const nltl_it_title = this.getCsvState(item.nltl_it_etat_valeur);
                                    return (
                                        <tr key={item.date}>
                                            <td className="tdLarge">{moment(item.date).format("DD/MM/YYYY")}</td>
                                            <td title={nltl_title} className={nltl_bg_color + "tdSmall"}>{item.index_nltl}</td>
                                            <td title={nltl_fr_title} className={nltl_fr_bg_color + "tdSmall"}>{item.index_nltl_fr}</td>
                                            <td title={nltl_it_title} className={nltl_it_bg_color + "tdSmall"}>{item.index_nltl_it}</td>
                                        </tr>
                                    );
                                }, this)}
                            </tbody>
                        </table>
                    </div>
                    <div className="valueCardActions">
                        <CSVLink data={this.state.values_csv} separator={";"} filename={"NLTL index value.csv"} className="exportButton hvr-bubble-left flex">
                            <i className="fas fa-file-csv fa-1x icon"></i>
                            <span><Trans>Exporter CSV</Trans></span>
                        </CSVLink>
                        <div className="dynamicButtons">
                            <Link to="wbs" className="exportButton hvr-bubble-left flex">
                                <i className="fa fa-table fa-1x icon"></i>
                                <span><Trans>Voir WBS</Trans></span>
                            </Link>
                            {
                                this.state.hasToken ? (
                                    <Link to="indexes" className="exportButton hvr-bubble-left flex">
                                        <i className="fa fa-info-circle fa-1x icon"></i>
                                        <span><Trans>Voir détails</Trans></span>
                                    </Link>) : null
                            }
                            <ButtonGraph toPage="NltlGraph"/>
                            <div className="warningMessage"><span className="warningTitle"><Trans>ATTENTION</Trans> : </span><Trans>Les valeurs en vert sont des valeurs estimées, tous les indices de base n'étant pas encore fournis par les sites EUROSTAT, ISTAT et INSEE</Trans></div>
                        </div>
                    </div>
                </section>
            );
        }
        return (
            <section className="row card flex">
                <div className="loaderCentered"></div>
            </section>
        );
    }
}

export class TableIndexStatsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indices: [],
            values: [],
            values_csv: [],
            isLoading: true,
            selectedIndice: 0,
        };
        this.changeIndice = this.changeIndice.bind(this);
    }

    async componentDidMount() {
        const indices = [];
        const response = await axios.get(`${config.baseURI}/api/indices?is_telt=false`);
        response.data.forEach(indice => {
            indices.push(indice);
        });
        await new Promise(resolve => this.setState({ indices: indices, selectedIndice: indices[0].id }, resolve));
        this.changeIndice();
    }

    async componentDidUpdate(prevProps) {
        if (this.props.selectedIndice !== prevProps.selectedIndice) {
            await this.changeIndice();
        }
    }

    async changeIndice(event) {
        const values = [];
        const values_csv = [];
        const newIndiceID = event ? event.target.value : this.props.selectedIndice;
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const revisionID = responseRevision.data[0].id;
        const responseParameters = await axios.get(`${config.baseURI}/api/indiceparameters/?is_telt=false`);
        const indiceParamID = responseParameters.data.find(item => item.indice.id == newIndiceID).id;
        const response = await axios.get(`${config.baseURI}/api/revision/${revisionID}/indiceparameter/${indiceParamID}/stats/`);
        const startDate = new Date(response.data.start_date);
        // VALUES
        values.push({ name: "Var. sur le dernier mois", value: (response.data.stats["var_dernier_mois"] * 100).toFixed(2), month: "", year: "" });
        values.push({ name: "Var. mensuelle moyenne", value: (response.data.stats["var_mensuelle_moyenne"] * 100).toFixed(2), month: "", year: "" });
        values.push({ name: "Var. sur un an", value: (response.data.stats["var_dernier_an"] * 100).toFixed(2), month: "", year: "" });
        values.push({ name: "Var. annuelle moyenne (au 1° janvier de l'année dernière)", value: (response.data.stats["var_annuelle_moyenne"] * 100).toFixed(2), month: "", year: "" });
        values.push({ name: "Var. depuis", value: (response.data.stats["var_since_start_date"] * 100).toFixed(2), month: "Month " + startDate.getMonth(), year: startDate.getFullYear() });
        // VALUES CSV
        values_csv.push({ name: "Var. sur le dernier mois", value: (response.data.stats["var_dernier_mois"] * 100).toFixed(2) });
        values_csv.push({ name: "Var. mensuelle moyenne", value: (response.data.stats["var_mensuelle_moyenne"] * 100).toFixed(2) });
        values_csv.push({ name: "Var. sur un an", value: (response.data.stats["var_dernier_an"] * 100).toFixed(2) });
        values_csv.push({ name: "Var. annuelle moyenne (au 1° janvier de l'année dernière)", value: (response.data.stats["var_annuelle_moyenne"] * 100).toFixed(2) });
        values_csv.push({ name: i18n.t("Var. depuis") + " " + i18n.t("Month " + startDate.getMonth()) + " " + startDate.getFullYear(), value: (response.data.stats["var_since_start_date"] * 100).toFixed(2) });
        await new Promise(resolve => this.setState({ isLoading: false, values: values, values_csv: values_csv, selectedIndice: newIndiceID }, resolve));
    }

    render() {
        if (!this.state.isLoading) {
            return (
                <section className="row card flex">
                    <div className="section-header">
                        <Trans>Variation de l'index</Trans>
                        <span className="indiceSelector">{this.state.indices.find(indice => indice.id == this.props.selectedIndice).name}</span>
                    </div>
                    <table className="table-striped indexStatsTable">
                        <tbody>
                            {this.state.values.map(item => {
                                return (
                                    <tr key={item.name}>
                                        <td className="tdLarge"><Trans>{item.name}</Trans>&nbsp;<Trans>{item.month}</Trans>&nbsp;{item.year}</td>
                                        <td className="tdSmall">{item.value + " %"}</td>
                                    </tr>
                                );
                            }, this)}
                        </tbody>
                    </table>
                    <div className="actions">
                        <CSVLink data={this.state.values_csv} separator={";"} filename={"Index stats.csv"} className="exportButton hvr-bubble-left flex">
                            <i className="fas fa-file-csv fa-1x icon"></i>
                            <span><Trans>Exporter CSV</Trans></span>
                        </CSVLink>
                    </div>
                </section>
            );
        }
        return (
            <section className="row card flex">
                <div className="loaderCentered"></div>
            </section>
        );
    }
}

TableIndexStatsCard.propTypes = {
    selectedIndice: PropTypes.number,
};

export class TableIndexValueCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indices: [],
            values: [],
            isLoading: true,
            selectedIndice: 0,
            totalNbMonthsCol: 0,
            revisionID: 0,
            csv: "",
        };
        this.changeIndice = this.changeIndice.bind(this);
        this.fetchData();
    }

    async fetchData() {
        const indices = await axios.get(`${config.baseURI}/api/indices?is_telt=false`).then(response => response.data);
        const responseRevision = await axios.get(`${config.baseURI}/api/revision/?is_published=true`);
        const fetchedRevisionID = responseRevision.data[0].id;
        await new Promise(resolve => this.setState({ indices: indices, selectedIndice: indices[0].id, revisionID: fetchedRevisionID }, resolve));
        await this.changeIndice();
        await this.exportCSV();
    }

    async changeIndice(event) {
        const values = [];
        const newIndiceID = event ? event.target.value : this.state.selectedIndice;
        const responseParameters = await axios.get(`${config.baseURI}/api/indiceparameters/?is_telt=false`);
        const indiceParamID = responseParameters.data.find(item => item.indice.id == newIndiceID).id;
        const response = await axios.get(`${config.baseURI}/api/revision/${this.state.revisionID}/indiceparameter/${indiceParamID}/values/`);
        for (let i = response.data.length - 1; i >= 0; i--) {
            if (moment(response.data[i].date).isSameOrAfter("2012-01-01", "day")) {
                values.push({
                    date: response.data[i].date,
                    value: response.data[i].value,
                    flag: response.data[i].flag,
                });
            }
        }
        await new Promise(resolve => this.setState({ isLoading: false, values: values, selectedIndice: newIndiceID }, resolve));
        this.props.updateSelectedIndice(newIndiceID);
    }

    async exportCSV() {
        const result = await axios.get(`${config.baseURI}/api/revision/${this.state.revisionID}/indicevalues/non_telt/export/`);
        const csv = result.data;
        await new Promise(resolve => this.setState({ totalNbMonthsCol: 0, csv: csv }, resolve));
    }

    getIndexClass(flag) {
        switch (flag) {
            case "S":
                return "s_cell ";
            default:
                return "";
        }
    }

    getState(flag, to_translate = true) {
        switch (flag) {
            case "S":
                return to_translate ? i18n.t("Estimé") : "Estimé";
            default:
                return "";
        }
    }

    render() {
        return (
            <section className="row card flex">
                <div className="section-header">
                    <Trans>Valeur de l'index</Trans>
                    <span className="indiceSelector">
                        <select name="indices" onChange={this.changeIndice} value={this.state.selectedIndice}>
                            {this.state.indices.map(item => {
                                return (<option key={item.id} value={item.id}>{item.name}</option>);
                            }, this)}
                        </select>
                    </span>
                </div>
                <div className="tableContainer">
                    <table className="table-striped indexTable">
                        <thead>
                            <tr>
                                <th><Trans>Date</Trans></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.values.map(item => {
                                const bg_color = this.getIndexClass(item["flag"]);
                                const cell_title = this.getState(item["flag"]);
                                return (
                                    <tr key={item.date}>
                                        <td className="tdLarge">{moment(item.date).format("DD/MM/YYYY")}</td>
                                        <td title={cell_title} className={bg_color}>{item.value}</td>
                                    </tr>
                                );
                            }, this)}
                        </tbody>
                    </table>
                </div>
                <div className="valueCardActions">
                    <CSVLink data={this.state.csv} separator={";"} filename={"Non_TELT_indexes_values.csv"} className="exportButton hvr-bubble-left flex indexesButton">
                        <i className="fas fa-file-csv fa-1x icon"></i>
                        <span><Trans>Exporter CSV</Trans></span>
                    </CSVLink>
                    <div className="dynamicButtons">
                        <ButtonGraph toPage="OthersGraph"/>
                        <div className="warningMessage"><span className="warningTitle">
                            <Trans>ATTENTION</Trans> : </span><Trans>Les valeurs en vert sont des valeurs estimées, elles ne sont pas fournies par les sites de référence ISTAT et INSEE</Trans>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

TableIndexValueCard.propTypes = {
    updateSelectedIndice: PropTypes.func.isRequired,
};

export class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: PAGES.NTL_INDEXES,
            selectedIndice: 20,
        };
        this.changePage = this.changePage.bind(this);
        this.updateSelectedIndice = this.updateSelectedIndice.bind(this);
    }

    updateSelectedIndice(value) {
        this.setState({ selectedIndice: value });
    }

    changePage(newPage) {
        this.setState({ page: newPage });
    }

    render() {
        let content;
        switch (this.state.page) {
            case PAGES.NTL_INDEXES:
                content = (
                    <div>
                        <TableNltlStatsCard/>
                        <TableNltlValueCard/>
                    </div>
                );
                break;
            case PAGES.OTHER_INDEXES:
                content = (
                    <div>
                        <TableIndexStatsCard selectedIndice={Number(this.state.selectedIndice)}/>
                        <TableIndexValueCard selectedIndice={Number(this.state.selectedIndice)} updateSelectedIndice={this.updateSelectedIndice}/>
                    </div>
                );
                break;
            default:
                content = "";
        }
        return (
            <div>
                <TabBar page={this.state.page} changePage={this.changePage} />
                {content}
            </div>
        );
    }
}
