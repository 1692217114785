import React from "react";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import config from "../config";
import { Trans } from "react-i18next";
import cookie from "react-cookies";

export class IndexesManagement extends React.Component {
    render() {
        return (
            <div className="content">
                <Title content="Gérer les index"/>
                <IndexesManagementCard/>
            </div>
        );
    }
}

export class IndexesManagementCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: {
                header: [],
                data: null,
            },
            isLoading: true,
            patchIndex: 1,
            indexToPatch: {},
            message: " ",
            icon: "",
        };
        this.patchIndex = this.patchIndex.bind(this);
    }

    async componentDidMount() {
        const table = {
            header: ["Index", "Sources", "Actions"],
            data: {},
        };
        const response = await axios.get(config.baseURI + "/api/indiceparameters/");
        response.data.forEach(e => {
            table.data[e.indice.name] = {};
        });
        response.data.forEach(e => {
            if (e.indice.name == "TP01 INSEE") {
                table.data["TP01"]["FR insee"] = e;
            } else if (e.indice.name == "TP05b INSEE") {
                table.data["TP05b"]["FR insee"] = e;
            } else {
                table.data[e.indice.name][e.country.name + " " + e.provider.name] = e;
            }
        });
        await new Promise(resolve => this.setState({ table: table, isLoading: false, indexToPatch: table.data["IPC hors tabac"]["FR eurostat"] }, resolve));
    }

    async patchIndex(e, key, keyY) {
        await new Promise(resolve => this.setState({
            message: " ",
            icon: "",
            indexToPatch: this.state.table.data[key][keyY],
            patchIndex: this.state.patchIndex + 1,
        }, resolve));
        this.forceUpdate();
    }

    async changeValue(e, param) {
        const newValue = this.state.indexToPatch;
        if (param === "enabled") {
            newValue["is_enabled"] = !newValue["is_enabled"];
        } else if (param === "pivot") {
            newValue.parameters[param] = parseFloat(e.target.value);
        } else {
            newValue.parameters[param] = e.target.value;
        }
        await new Promise(resolve => this.setState({ indexToPatch: newValue }, resolve));
    }

    async saveModifications() {
        await axios.patch(config.baseURI + "/api/indiceparameters/" + this.state.indexToPatch.id + "/",
            { is_published: this.state.indexToPatch.is_enabled,  parameters: this.state.indexToPatch.parameters },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + cookie.load("token"),
                },
            })
            .then(async () => {
                return new Promise(resolve => this.setState({ message: "Index modifié avec succès", icon: "check" }, resolve));
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 401 :
                        return new Promise(resolve => this.setState({ message: "Vous devez être connecté en tant qu'administrateur pour effectuer cette action", icon: "lock" }, resolve));
                    default :
                        return new Promise(resolve => this.setState({ message: "Impossible de modifier cet index", icon: "times" }, resolve));
                }
            });
    }

    render() {
        if (!cookie.load("token")) {
            return (
                <div className="contentAdmin">
                    <div className="actionAdmin"><Trans>Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation</Trans></div>
                </div>
            );
        }
        if (!this.state.isLoading) {
            return (
                <div className="indexesManagementContent">
                    <div className="indexesManagementCard">
                        <table className="table-striped">
                            <thead>
                                <tr>
                                    {this.state.table.header.map(function (item) {
                                        return (
                                            <th key={item} className={"th" + item}><Trans>{item}</Trans></th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(this.state.table.data).sort().map(function (key, index) {
                                    return (
                                        Object.keys(this.state.table.data[key]).map(function (keyY, indexY) {
                                            return (
                                                <tr key={key + " " + keyY}>
                                                    {indexY == 0 &&
                                                        <td rowSpan={Object.keys(this.state.table.data[key]).length} className={"boldRow row" + index % 2}><Trans>{key}</Trans></td>
                                                    }
                                                    <td>{keyY}</td>
                                                    <td className="tdPatch">
                                                        <div className="patchAction" onClick={(e) => this.patchIndex(e, key, keyY)}>
                                                            <i className="fa fa-pencil-alt fa-1x"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        }.bind(this))
                                    );
                                }.bind(this))}
                            </tbody>
                        </table>
                    </div>
                    {this.state.patchIndex > 1 &&
                        <div className="indexesManagementParametersCard">
                            <div className="indexesParametersTitle"><Trans>Modifier un index</Trans></div>
                            <div style={{ marginTop: "20px" }}>
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Nom</Trans></div>
                                    <input type="text" className="indexesParametersInput textInput" disabled value={this.state.indexToPatch.indice.name}/>
                                </div>
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Source</Trans></div>
                                    <select className="indexesParametersInput" disabled value={this.state.indexToPatch.country.name + " " + this.state.indexToPatch.provider.name}>
                                        <option>FR insee</option>
                                        <option>IT istat</option>
                                        <option>FR eurostat</option>
                                        <option>IT eurostat</option>
                                    </select>
                                </div>
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Fréquence</Trans></div>
                                    <select className="indexesParametersInput" value={this.state.indexToPatch.parameters.freq} onChange={(e) => this.changeValue(e, "freq")}>
                                        <option value="M"><Trans>Mensuel</Trans></option>
                                        <option value="Q"><Trans>Trimestriel</Trans></option>
                                    </select>
                                </div>
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Base</Trans></div>
                                    <input className="indexesParametersInput textInput" type="date" value={moment(this.state.indexToPatch.parameters.base100).format("YYYY-MM-DD")} onChange={(e) => this.changeValue(e, "base100")}/>
                                </div>
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Type</Trans></div>
                                    <select className="indexesParametersInput" value={this.state.indexToPatch.parameters.type} onChange={(e) => this.changeValue(e, "type")}>
                                        <option value="indice">Indice</option>
                                        <option value="rate">Rate</option>
                                    </select>
                                </div>
                                {this.state.indexToPatch.provider.name !== "systra" &&
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Pivot</Trans></div>
                                    <input className="indexesParametersInput textInput" type="number" step="0.00000001" value={this.state.indexToPatch.parameters.pivot} onChange={(e) => this.changeValue(e, "pivot")}/>
                                </div>
                                }
                                {this.state.indexToPatch.provider.name === "istat" &&
                                <div>
                                    <div className="indexesParametersRow">
                                        <div className="indexesParametersLabel">Area</div>
                                        <input type="text" className="indexesParametersInput textInput" value={this.state.indexToPatch.parameters.area} onChange={(e) => this.changeValue(e, "area")}/>
                                    </div>
                                    <div className="indexesParametersRow">
                                        <div className="indexesParametersLabel">Dataset</div>
                                        <input type="text" className="indexesParametersInput textInput" value={this.state.indexToPatch.parameters.dataset} onChange={(e) => this.changeValue(e, "dataset")}/>
                                    </div>
                                    <div className="indexesParametersRow">
                                        <div className="indexesParametersLabel">JsonSpec</div>
                                        <textarea rows="5" className="indexesParametersInput textInput" value={JSON.stringify(this.state.indexToPatch.parameters.json_spec)} onChange={(e) => this.changeValue(e, "json_spec")}></textarea>
                                    </div>
                                </div>
                                }
                                {this.state.indexToPatch.provider.name === "eurostat" &&
                                <div>
                                    <div className="indexesParametersRow">
                                        <div className="indexesParametersLabel">Html Parameters</div>
                                        <textarea rows="5" className="indexesParametersInput textInput" value={this.state.indexToPatch.parameters.html_parameters} onChange={(e) => this.changeValue(e, "html_parameters")}></textarea>
                                    </div>
                                </div>
                                }
                                {this.state.indexToPatch.provider.name === "insee" &&
                                <div>
                                    <div className="indexesParametersRow">
                                        <div className="indexesParametersLabel">Id Bank</div>
                                        <input type="text" className="indexesParametersInput textInput" value={this.state.indexToPatch.parameters.id_bank} onChange={(e) => this.changeValue(e, "id_bank")}/>
                                    </div>
                                </div>
                                }
                                <div className="indexesParametersRow">
                                    <div className="indexesParametersLabel"><Trans>Actif</Trans></div>
                                    <input type="checkbox" className="checkboxIndex" checked={this.state.indexToPatch.is_enabled} onChange={(e) => this.changeValue(e, "enabled")}/>
                                </div>
                                {!this.state.patchIndex &&
                                    <div className="indexesManagementParametersCard"></div>
                                }
                                <button className="exportButton hvr-bubble-left flex modifyButton" onClick={() => this.saveModifications()}>
                                    <i className="fa fa-save fa-1x icon"></i>
                                    <span><Trans>Sauvegarder</Trans></span>
                                </button>
                                <div className="results">
                                    <div className="message"><Trans>{this.state.message}</Trans></div>
                                    <i className={"fa fa-" + this.state.icon + " fa-4x iconMessage"}></i>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            );
        }
        return (
            <div className="indexesManagementContent">
                <div className="indexesManagementCard">
                    <div className="loaderCentered"></div>
                </div>
            </div>
        );
    }
}
