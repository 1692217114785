import React from "react";
import axios from "axios";
import moment from "moment";
import { Title } from "./graph";
import config from "../config";
import { Trans } from "react-i18next";
import cookie from "react-cookies";
import i18next from "i18next";

export class WBSManagement extends React.Component {
    render() {
        return (
            <div className="content">
                <Title content="Créer un nouveau WBS"/>
                <WBSManagementCard/>
            </div>
        );
    }
}

export class WBSManagementCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            table: {
                header: [],
                data: null,
            },
            isLoading: true,
            inputs: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            name: "",
            date: moment().format("YYYY-MM-DD"),
            message: " ",
            icon: "",
            isUploading: false,
        };
    }

    changeValue(nb, e) {
        const check = this.state.inputs;
        check[nb] = e.target.value;
        this.forceUpdate();
    }

    changeName(e) {
        this.setState({ name: e.target.value });
    }

    changeDate(e) {
        this.setState({ date: moment(e.target.value).format("YYYY-MM-DD") });
    }

    total(country) {
        let result = 0;
        for (let i = this.state.inputs.length - 1; i >= 0; i--) {
            if (country == "france" && (i % 2) == 1) {
                result += parseFloat(this.state.inputs[i]);
            }
            if (country == "italia" && (i % 2) == 0) {
                result += parseFloat(this.state.inputs[i]);
            }
            if (country == "all") {
                result += parseFloat(this.state.inputs[i]);
            }
        }
        return result.toFixed(3);
    }

    async saveWBS() {
        const payLoads = [];
        this.state.inputs.map((key, index) => {
            payLoads.push({
                wbs_parameter: { id: (index + 1) },
                value_mde: this.state.inputs[index],
            });
        });
        await axios.post(`${config.baseURI}/api/wbs/`,
            {
                is_default: true,
                date_published: moment(this.state.date).format("YYYY-MM"),
                label: this.state.name,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Token ${cookie.load("token")}`,
                },
            })
            .then(async response => {
                await axios.post(`${config.baseURI}/api/wbs/${response.data.id}/parameters/`, payLoads,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": `Token ${cookie.load("token")}`,
                        },
                    })
                    .then(() => {
                        return new Promise(resolve => this.setState({ message: "WBS créé avec succès", icon: "check" }, resolve));
                    })
                    .catch(error => {
                        switch (error.response.status) {
                            case 401 :
                                return new Promise(resolve => this.setState({ message: "Vous devez être connecté en tant qu'administrateur pour effectuer cette action", icon: "lock" }, resolve));
                            default :
                                return new Promise(resolve => this.setState({ message: "Impossible de créer un nouveau WBS", icon: "times" }, resolve));
                        }
                    });
            })
            .catch((error) => {
                switch (error.response.status) {
                    case 401 :
                        return new Promise(resolve => this.setState({ message: "Vous devez être connecté en tant qu'administrateur pour effectuer cette action", icon: "lock" }, resolve));
                    default :
                        return new Promise(resolve => this.setState({ message: "Impossible de créer un nouveau WBS", icon: "times" }, resolve));
                }
            });
    }

    async componentDidMount() {
        const table = {
            header: [],
            data: {},
        };
        const paramsResponse = await axios.get(config.baseURI + "/api/wbs/parameters/");
        table.data["Génie Civil + environnement + déviations réseaux"] = { IT: [], FR: [] };
        paramsResponse.data.forEach(e => {
            table.data[e.name] = { IT: [], FR: [] };
        });
        table.data["Total"] = { IT: [], FR: [], Total: [] };
        const responseDefault = await axios.get(config.baseURI + "/api/wbs/?is_default=true");
        table.header.unshift({ name: responseDefault.data[0].label, subtitle: moment(responseDefault.data[0].date_published).format("DD/MM/YYYY") });
        table.header.unshift({ name: "Pays", subtitle: "" });
        table.header.unshift({ name: "Version du projet", subtitle: "Md€ aux conditions économiques de" });
        const responseWbs = await axios.get(config.baseURI + "/api/wbs/" + responseDefault.data[0].id + "/parameters/");
        responseWbs.data.forEach(line => {
            table.data[line.wbs_parameter.name][line.wbs_parameter.country.name].unshift(line.value_mde);
        });
        const responseTotal = await axios.get(config.baseURI + "/api/wbs/" + responseDefault.data[0].id + "/total/");
        Object.keys(responseTotal.data).map(key => {
            table.data[key]["IT"].unshift(responseTotal.data[key].IT);
            table.data[key]["FR"].unshift(responseTotal.data[key].FR);
            if (key == "Total") {
                table.data[key]["Total"].unshift(responseTotal.data[key].Total);
            }
        });
        await new Promise(resolve => this.setState({ table: table, isLoading: false }, resolve));
    }

    render() {
        if (!cookie.load("token")) {
            return (
                <div className="contentAdmin">
                    <div className="actionAdmin"><Trans>Veuillez vous connecter en tant qu'administrateur en cliquant sur l'icone dans le menu de navigation</Trans></div>
                </div>
            );
        }
        if (!this.state.isLoading) {
            return (
                <div className="WBSCard">
                    <table className="table-striped WBSManagementTable">
                        <thead>
                            <tr>
                                {this.state.table.header.map(function (item, index) {
                                    if (index === 0) {
                                        return (
                                            <th key={item.name} style={{ width: "400px" }}>
                                                <div><Trans>{item.name}</Trans></div>
                                                <div className="tableHeaderSubtitle"><Trans>{item.subtitle}</Trans></div>
                                            </th>
                                        );
                                    } else if (index === 1) {
                                        return (
                                            <th key={item.name + "s"} style={{ width: "20px", textAlign: "center" }}>
                                                <div><Trans>{item.name}</Trans></div>
                                                <div className="tableHeaderSubtitle">{item.subtitle}</div>
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th key={item.name} style={{ width: "150px" }}>
                                                <div>{item.name}</div>
                                                <div className="tableHeaderSubtitle">{item.subtitle}</div>
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.state.table.data).map(function (key, index) {
                                return [
                                    <tr key={"a" + key}>
                                        <td rowSpan={Object.keys(this.state.table.data[key]).length} key={key} className={"row" + index % 2}><Trans>{key}</Trans></td>
                                        <td className="countryCell">FR</td>
                                        {this.state.table.data[key].FR.map(function (item) {
                                            return <td key={item}>{item.toFixed(3)}</td>;
                                        })}
                                    </tr>,
                                    <tr key={"b" + key}>
                                        <td className="countryCell">IT</td>
                                        {this.state.table.data[key].IT.map(function (item) {
                                            return <td key={item}>{item.toFixed(3)}</td>;
                                        })}
                                    </tr>,
                                ];
                            }.bind(this))}
                            <tr>
                                <td className="totalCell"></td>
                                {this.state.table.data["Total"].Total.map(function (item) {
                                    return <td key={item} className="totalCell">{item.toFixed(3)}</td>;
                                })}
                            </tr>
                        </tbody>
                    </table>
                    <table className="table-striped tableInputs">
                        <thead>
                            <tr>
                                <th>
                                    <div><input type="text" placeholder={i18next.t("Nom")} className="inputName" value={this.state.name} onChange={(e) => this.changeName(e)}/></div>
                                    <div className="tableHeaderSubtitle"><input type="date" className="inputName" value={this.state.date} onChange={(e) => this.changeDate(e)}/></div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td className="tdInput">{ (parseFloat(this.state.inputs[0]) + parseFloat(this.state.inputs[2])).toFixed(3) }</td></tr>
                            <tr><td className="tdInput">{ (parseFloat(this.state.inputs[1]) + parseFloat(this.state.inputs[3])).toFixed(3) }</td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[0]} onChange={(e) => this.changeValue(0, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[1]} onChange={(e) => this.changeValue(1, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[2]} onChange={(e) => this.changeValue(2, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[3]} onChange={(e) => this.changeValue(3, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[4]} onChange={(e) => this.changeValue(4, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[5]} onChange={(e) => this.changeValue(5, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[6]} onChange={(e) => this.changeValue(6, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[7]} onChange={(e) => this.changeValue(7, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[8]} onChange={(e) => this.changeValue(8, e)}/></td></tr>
                            <tr><td className="tdInput"><input type="text" className="inputValue" value={this.state.inputs[9]} onChange={(e) => this.changeValue(9, e)}/></td></tr>
                            <tr><td className="tdInput">{ this.total("italia") }</td></tr>
                            <tr><td className="tdInput">{ this.total("france") }</td></tr>
                            <tr><td className="totalCell">{this.total("all")}</td></tr>
                        </tbody>
                    </table>
                    {!this.state.isUploading && (
                        <div className="results">
                            <button className="exportButton hvr-bubble-left flex saveButton" onClick={() => this.saveWBS()}>
                                <i className="fa fa-save fa-1x icon"></i>
                                <span><Trans>Sauvegarder</Trans></span>
                            </button>
                            <div className="message"><Trans>{this.state.message}</Trans></div>
                            <i className={"fa fa-" + this.state.icon + " fa-4x iconMessage"}></i>
                        </div>
                    )}
                    {this.state.isUploading && (
                        <div className="results">
                            <div className="loader"></div>
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div className="WBSCard">
                <div className="loaderCentered"></div>
            </div>
        );
    }
}
